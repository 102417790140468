import React from "react";
import PageHeader from "./Common/PageHeader";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Understand and read our privacy policy for new customers </title>
        <meta
          name="keywords"
          content="creative items,unique items,creative items to sell,creative craft items,creative gift items,creative home decor products,unique home accessories,best home decor gifts,unique kitchen decor,unique modern home decor,unique decorative items,unique house accessories,cheap home decor gifts,unique kitchen products,unique kitchenware itemsunique kitchen items,home and kitchen unique products,unique kitchen accessories,unique kitchen tools"
        />
        <meta
          name="description"
          content="Our privacy policy puts customers first so we never leak any of your data to third-party clients. At Bakumia, we keep your data only for suggestion purposes"
        />
      </Helmet>
      <PageHeader title="Privacy Policy" />
      <section className="">
        <div className="container-fluid p-5">
          <div className="row ">
            <div className="col-md-12 mt-5 ">
              <h2 className="display-6 text-center fw-medium">
                Privacy Policy
              </h2>
              <hr className="hr mb-4" />
              <div className="text-muted lh-lg text-center mb-5">
                <p>
                  Bakumia protects the privacy of our valued customers at all
                  costs. bakumia.pk is operated by Bakumia and the privacy
                  policy applies to your use of the website using the domain
                  bakumia.pk.
                </p>
                <p>
                  Hence, you have a right to know how we access your data, use
                  personal data, and who has access to that data. Accepting to
                  use the website means you have read the privacy policy and
                  have agreed to the terms of use.Our website is well secured
                  and encrypted. Purchases made through our website for any
                  items in our store are secured. You can easily shop through
                  our vast catalog of unique home accessories and fill up your
                  empty spaces at home.
                </p>
              </div>
            </div>
            <div className="col-md-12 mt-2 ">
              <h3 className="">Use of Personal Data</h3>
              <div className="text-muted lh-lg">
                <p>
                  At Bakumia, we take your personal data for the purpose of
                  delivering the product and keeping a record of any changes.
                  Your personal data includes your name, address, phone number,
                  email address, and DOB (date of birth).
                </p>
                <p>
                  The data is also used to send you information about the brand
                  and any upcoming offers that we may have through email and
                  SMS. The data is only kept until we need it to provide you
                  surface, after which it is permanently deleted.
                </p>
              </div>
            </div>
            <div className="col-md-12 mt-2 ">
              <h3 className="">
                What information do we share with third parties?
              </h3>
              <div className="text-muted lh-lg lh-5">
                <p>
                  Bakumia respects the privacy of our customers and only shares
                  personal information with third parties who perform services
                  on the behalf of the company to complete authored
                  transactions. The personal information shared via these
                  companies cannot be shared anywhere else.
                </p>
                <p>
                  We do not sell, re-use, or share any of the personal
                  information that you share with Bakumia with any unaffiliated
                  third party.
                </p>
              </div>
            </div>
            <div className="col-md-12 mt-2 ">
              <h3 className="">Understand Your Rights</h3>
              <div className="text-muted lh-lg lh-5">
                <p>
                  You own the right to see any of the personal information
                  Bakumia holds against you. if you think your data is incorrect
                  or irrelevant, you can contact us to get the data removed or
                  changed.
                </p>
              </div>
            </div>
            <div className="col-md-12 mt-2 ">
              <h3 className="">Use of Cookies</h3>
              <div className="text-muted lh-lg lh-5">
                <p>
                  Cookies are small files that are embedded in your system for
                  the sole purpose of collecting data to make your next visit
                  better. However, at Bakumia we do not have any cookie policy
                  and do not store any cookies in your system to get
                  information.
                </p>
              </div>
            </div>
            <div className="col-md-12 mt-2 ">
              <h3 className="">Third-Party Cookies</h3>
              <div className="text-muted lh-lg lh-5">
                <p>
                  Since we have no cookie policy for permanent or temporary
                  cookies, we do not have third-party cookies as well on the
                  Bakumia website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;

import apiClient from "../utils/api-client";

export function register(user) {
  const body = new FormData();
  body.append("full_name", user.full_name);
  body.append("email", user.email);
  body.append("phone", user.phone);
  body.append("password", user.password);
  body.append("city_id_fk", user.city);
  body.append("address", user.address);
  body.append("referral_code", user.referral_code);

  return apiClient.post("/register", body);
}

export function login(user) {
  return apiClient.post("/login", user);
}

export function NewslatterSubscribe(email) {
  return apiClient.post("/newslatter", email);
}

export function forgotPassord(user) {
  const body = new FormData();
  body.append("email", user.userEmail);

  return apiClient.post("/forgot-password/", body);
}

export function contact(body) {
  return apiClient.post("/contact", body);
}

export function checkout(body) {
  return apiClient.post("/checkout", body);
}

export function addToWishlist(data) {
  return apiClient.post("/wishlist", data);
}

export function addShippingAddress(user) {
  const body = new FormData();
  body.append("receiver_name", user.receiver_name);
  body.append("receiver_phone", user.receiver_phone);
  body.append("street_address", user.street_address);
  body.append("zip_code", user.zip_code);
  body.append("city_id_fk", user.city_id_fk);
  body.append("user_id_fk", user.user_id_fk);

  return apiClient.post("/create-shipping-address", body);
}

export function update(user) {
  const body = new FormData();
  body.append("user_id", user.user_id);
  body.append("full_name", user.full_name);
  body.append("email", user.email);
  body.append("phone", user.phone);
  body.append("city_id_fk", user.city);
  body.append("address", user.address);

  return apiClient.post("/update-user-details", body);
}

export function changePassword(data) {
  const body = new FormData();
  body.append("user_id", data.user_id);
  body.append("old_password", data.old_password);
  body.append("password", data.password);
  body.append("confirm_password", data.confirm_password);

  return apiClient.post("/change-password", body);
}

import React, { useContext, useState } from "react";
import "./Navbar.css";
import logo from "../../assets/brand/logo1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Topbar from "./Topbar";
import OffcanvasMenu from "./OffcanvasMenu";
import NavLinks from "./NavLinks";
import { Link, NavLink } from "react-router-dom";
import apiClient from "../../utils/api-client";
import { useNavigate } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import CartContext from "../../contexts/CartContext";

const Navbar = () => {
  const { user } = useContext(UserContext);
  const { cart } = useContext(CartContext);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const cartCount = cart.length;

  const handleSearch = async (event) => {
    event.preventDefault();
    try {
      const response = await apiClient.get(
        `/search/products?product_name=${searchQuery}`
      );
      const searchData = response.data.data;
      setSearchQuery("");
      navigate("/search-results/", { state: { searchResults: searchData } });
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <section>
      <Topbar />
      <div className="container-fluid">
        <nav
          className=" col-sm-12 navbar navbar-expand-lg navbar-light bg-light"
          aria-label="Offcanvas navbar large  "
        >
          <div className="col-lg-3 col-md-12 col-12 d-flex align_center justify-content-between">
            <div className="navbar-brand col-lg-12 col-md-10 col-sm-10 m-0">
              <Link to="/">
                <img src={logo} alt="" className="brand-logo img-fluid" />
              </Link>
            </div>

            {/* Offcanvas menu for mobile and tablet */}
            <div className="col-md-2 col-sm-2 d-flex justify-content-end ">
              <OffcanvasMenu user={user} cartCount={cartCount} />
            </div>
          </div>
          <div className="navbar-search col-lg-6 col-md-12 col-sm-12 d-flex flex-grow-1 w-25">
            <form
              action=""
              className=" align_center navbar_form flex-grow-1 m-0 p-0"
              onSubmit={handleSearch}
            >
              <input
                type="text"
                className="navbar_search rounded"
                placeholder="What do you need?"
                value={searchQuery}
                onChange={handleInputChange}
                required
              />
              <button type="submit" className="search_button">
                Search
              </button>
            </form>
          </div>
          <div className="align_center col-lg-3 col-md-4  d-none d-lg-block d-flex">
            <div className="align_center justify-content-end d-flex gap-4">
              <Link to="/cart/">
                <i className="fa fa-shopping-bag icon">
                  <sup id="total" className="ms-1">
                    {cartCount}
                  </sup>{" "}
                </i>
              </Link>
              <Link to="/wishlist/">
                <i className="fa fa-heart icon">
                  <sup id="total" className="ms-1">
                    {/* {wishListCount} */}
                  </sup>{" "}
                </i>
              </Link>
              {!(user !== null && user !== "") && (
                <NavLink to="login/">
                  <button className="login_button button">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ fontSize: 15, paddingRight: 8 }}
                    />
                    Login
                  </button>
                </NavLink>
              )}
              {user !== null && user !== "" && (
                <NavLink to={"/account/profile/"}>
                  <button className="login_button button">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ fontSize: 15, paddingRight: 8 }}
                    />
                    Account
                  </button>
                </NavLink>
              )}
            </div>
          </div>
        </nav>
      </div>
      <NavLinks />
    </section>
  );
};

export default Navbar;

import React from "react";
import "./BlogPage.css";
import PageHeader from "../Common/PageHeader";
import BlogItem from "./BlogItem";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  return (
    <>
      <Helmet>
        <title>Tips and Tricks on How to Use Everyday Products </title>
        <meta
          name="keywords"
          content="new unique items,creative items for home,creative home decor items,creative decorative items,creative products to sell online,creative memories items for sale,creative gift supplies,creative item for home and office,unique products for home,unique home products,unique office wall decor,interesting home products,unique decorative pieces,unique home essentials,funky home decor items,unique home decorative items,unique home office decor,unique household gadgets"
        />
        <meta
          name="description"
          content="Have no idea where to start decorating your house? Get tips on how to use unique home products from Bakumia to use in your house and make every corner stand out."
        />
      </Helmet>
      <PageHeader title="Blog" />
      <section className="container-fluid my-5">
        <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4">
          <BlogItem />
        </div>
      </section>
    </>
  );
};

export default BlogPage;

import React, { useState, useEffect, useRef, useCallback } from "react";
import PageHeader from "./../Common/PageHeader";
import VideoProductCard from "../Shop/VideoProductCard";
import apiClient from "../../utils/api-client";
import CardSkeleton from "../Common/CardSkeleton";
import Loader from "../Common/Loader";

const VideosPage = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const productContainerRef = useRef(null);

  const fetchProducts = useCallback(async (page) => {
    console.log(`Fetching page: ${page}`); // Debug log
    try {
      setIsFetching(true);
      const res = await apiClient.post("/is-youtube", { page });
      const newProducts = res.data.data;
      const fetchedTotalPages = res.data.total_pages;
      setTotalPages(fetchedTotalPages);

      setProducts((prevProducts) => {
        const uniqueNewProducts = newProducts.filter(
          (newProduct) =>
            !prevProducts.some(
              (product) => product.product_id === newProduct.product_id
            )
        );
        return [...prevProducts, ...uniqueNewProducts];
      });
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts(page);
  }, [fetchProducts, page]);

  const handleScroll = useCallback(() => {
    if (
      !isFetching &&
      productContainerRef.current &&
      productContainerRef.current.getBoundingClientRect().bottom <=
        window.innerHeight + 10 &&
      page < totalPages
    ) {
      console.log(`Adding page: ${page + 1} to queue`); // Debug log
      setPage((prevPage) => prevPage + 1);
    }
  }, [isFetching, page, totalPages]);

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [handleScroll]);

  // Debounce function to limit the rate of function execution
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  return (
    <>
      <PageHeader title="Videos" />
      <section
        className="videos_product_section pt-5"
        ref={productContainerRef}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            {error && <em className="form_error">{error}</em>}
            {isLoading && <CardSkeleton cards={8} />}
            {products &&
              products.map((product) => (
                <div
                  className="col-lg-3 col-md-4 col-sm-12 p-2"
                  key={product.product_id}
                >
                  <VideoProductCard
                    key={product.product_id}
                    product={product}
                    youtubeVideoUrl={product.youtube_video_id}
                  />
                </div>
              ))}
            {isFetching && <Loader />}
          </div>
        </div>
      </section>
    </>
  );
};

export default VideosPage;

import React from "react";
import PageHeader from "./Common/PageHeader";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <title>
          Our terms and conditions apply to all our customers at Bakumia{" "}
        </title>
        <meta
          name="keywords"
          content="creative items,unique items,creative items to sell,creative craft items,creative gift items,creative home decor products,unique home accessories,best home decor gifts,unique kitchen decor,unique modern home decor,unique decorative items,unique house accessories,cheap home decor gifts,unique kitchen products,unique kitchenware itemsunique kitchen items,home and kitchen unique products,unique kitchen accessories,unique kitchen tools"
        />
        <meta
          name="description"
          content="We have certain terms and conditions that you have to fulfill before shopping at Bakumia. Please read through the points here and click on the  agree option. "
        />
      </Helmet>
      <PageHeader title="Terms of Services" />
      <section className="">
        <div className="container-fluid p-5">
          <div className="row ">
            <div className="col-md-12 mt-5 lh-lg">
              <h2 className="display-6 text-center fw-medium">
                Terms of Services at Bakumia
              </h2>
              <hr className="hr mb-4" />
              <div className="text-muted  text-center mb-5">
                <p>
                  You, the customer, buyer, or the end user is requested to read
                  all the terms and conditions and terms of services along with
                  the privacy policy for Bakumia.
                </p>
                <p>
                  Bakumia.pk will be referred to as the “site”, “company, “we”,
                  or the “website”. Reading these terms or using the website
                  means the terms and conditions are effective and will be put
                  into practice.
                </p>
              </div>

              <p>
                If the terms of services do not stand true in other documents,
                they will be used for the purpose of keeping conditions for the
                usage on this site. If you disagree with the TERMS OF
                SERVICES/CONDITIONS you cannot use the website in any way.
              </p>
              <p>
                For this purpose, therefore, the privacy policy and terms and
                conditions have to be accepted. The services are accepted after
                you click on the affirmation “check box” and press the continue
                button that shows your acceptance of conditions.
              </p>
              <p>
                The company has the right to revise the terms and conditions
                along with the privacy policy at any time. However, all the
                updates and changes will be notified through notifications on
                our website. The revised conditions will be effective as soon as
                the notification is posted on the site.
              </p>
              <p>
                Please read all the terms and conditions carefully to avoid any
                problems. These conditions are changed from time to time but
                serve as a binding contract between the company and you. If you
                buy or visit the website, you are agreeing to all these
                conditions.
              </p>
              <p>
                Moreover, we will send you notifications through emails on your
                account activity and any purchases that you may make, the emails
                are also directed to any promotions or updates on our products.
                You have the option to cancel these emails at any time by
                clicking on the UNSUBSCRIBE button in our emails. For more
                information on how we store and collect your personal data,
                please see the privacy details.
              </p>
              <p>
                You are required to register before making a purchase so that
                your information can be safely kept for future correspondence.
              </p>
            </div>

            <div className="col-md-12 mt-2 ">
              <h3 className="">Terms of Services</h3>
              <div className="text-muted lh-lg">
                <p>
                  The terms of services applicable while buying on our site are:
                </p>
                <ul>
                  <li>
                    Items can be exchanged in case of defection or wrong items
                    delivered
                  </li>
                  <li>Delivery charges are to be paid by the consumer</li>
                  <li>The exchange policy starts 7 days after the delivery</li>
                  <li>The minimum value of an order is (insert value)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;

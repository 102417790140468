import React from "react";
import PageHeader from "./Common/PageHeader";
import { Helmet } from "react-helmet";

const ReturnPolicy = () => {
  return (
    <>
      <Helmet>
        <title>
          Our return policy applies to new and existing Bakumia customers{" "}
        </title>
        <meta
          name="keywords"
          content="creative items,unique items,creative items to sell,creative craft items,creative gift items,creative home decor products,unique home accessories,best home decor gifts,unique kitchen decor,unique modern home decor,unique decorative items,unique house accessories,cheap home decor gifts,unique kitchen products,unique kitchenware itemsunique kitchen items,home and kitchen unique products,unique kitchen accessories,unique kitchen tools"
        />
        <meta
          name="description"
          content="We return products to all customers for valid reasons. If you find the product unfit for you, our customer service at Bakumia will be happy to assist you"
        />
      </Helmet>
      <PageHeader title="Return Policy" />
      <section className="">
        <div className="container-fluid p-5">
          <div className="row ">
            <div className="col-md-12 mt-5 ">
              <h2 className="display-6 text-center fw-medium">
                Return and Exchange Policy at Bakumia
              </h2>
              <hr className="hr mb-4" />
              <div className="text-muted lh-lg text-center mb-5">
                <p>
                  Any product bought from the online store at Bakumia can be
                  exchanged within 7 days after receiving the product. However,
                  please make sure that the product is undamaged and unused, and
                  that the original packing is still there. Also, make sure that
                  you have the original invoice attached to the package at the
                  time of return.
                </p>
                <p>
                  The items bought during the sale cannot be exchanged. The
                  original product may vary from the photographs due to
                  differences in lighting used during photoshoots. The exact
                  color may also vary, which is why products cannot be exchanged
                  for this reason.
                </p>
                <p>
                  Bakumia will only exchange products if the delivered product
                  is damaged at the time of delivery or is defected. Contact us
                  on our contact number (insert contact number) within 7 days of
                  the delivery to exchange the product.
                </p>
              </div>
            </div>

            <div className="col-md-12 mt-2 ">
              <h3 className="">Refunds and Policy</h3>
              <div className="text-muted lh-lg">
                <p>
                  Bakumia does not have a refund policy. We only offer an
                  exchange on products. However, you may be eligible for refunds
                  in exceptional cases. For example, if an item goes out of
                  stock, the amount will be refunded to you.
                </p>
                <p>
                  The company is not responsible for any shipping or handling
                  costs, they are non-refundable and are not covered by the
                  company in case a product has to be exchanged.
                </p>
                <p>
                  It takes up to 7 days for processing returns to progress. The
                  transaction will be completed after the order is checked and
                  gets approved for a return.
                </p>
              </div>
            </div>
            <div className="col-md-12 mt-2 ">
              <h3 className="">Canceling Your Order</h3>
              <div className="text-muted lh-lg">
                <p>
                  You have the chance to cancel your order before it processes.
                  However, if the order is shipped, you receive a tracking ID to
                  track your order. During this time you cannot cancel the
                  order. After the order is received, you have the option to
                  “return or exchange” your product and the relevant policy will
                  be applied.
                </p>
                <p>
                  Bakumia has the right to cancel your order if it goes out of
                  stock, has a pricing error, or your credit/debit card payment
                  gets declined by your bank.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReturnPolicy;

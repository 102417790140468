import React from "react";
import "./Slider.css";
import slideImage1 from "../../assets/media/slider_images/1693568974.webp";
import slideImage2 from "../../assets/media/slider_images/1693568883.webp";
import slideImage3 from "../../assets/media/slider_images/1693568694.webp";
import { NavLink } from "react-router-dom";

const Slider = () => {
  return (
    <section>
      <div
        id="carouselExampleSlidesOnly"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={slideImage1}
              className="d-block w-100 img-fluid max-width: 100%"
              alt="..."
            />
            <div className="slider_card mb-3">
              <div className="slider_card_body">
                <h5 className="slider_card_category">CLEANING PRODUCTS</h5>
                <h2 className="slider_card_title">Get upto 30% Discount Now</h2>
                <p className="slider_card_text">You will love these products</p>
                <NavLink to="/shop/" className=" btn-primary slider_btn">
                  Shop Now
                </NavLink>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={slideImage2}
              className="d-block w-100 img-fluid max-width: 100%"
              alt="..."
            />
            <div className="slider_card mb-3">
              <div className="slider_card_body">
                <h5 className="slider_card_category">CLEANING PRODUCTS</h5>
                <h2 className="slider_card_title">
                  Get 10% off on all products
                </h2>
                <p className="slider_card_text">
                  Hurry up! before its too late
                </p>
                <NavLink to="/shop/" className=" btn-primary slider_btn">
                  Shop Now
                </NavLink>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={slideImage3}
              className="d-block w-100 img-fluid max-width: 100%"
              alt="..."
            />
            <div className="slider_card mb-3">
              <div className="slider_card_body">
                <h5 className="slider_card_category">CLEANING PRODUCTS</h5>
                <h2 className="slider_card_title">Get Free Delivery Now</h2>
                <p className="slider_card_text">on orders above 2000</p>
                <NavLink to="/shop/" className=" btn-primary slider_btn">
                  Shop Now
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;

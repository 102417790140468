import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../contexts/UserContext";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { toast } from "react-toastify";
import CartContext from "../../contexts/CartContext";
import Skeleton from "react-loading-skeleton";
import wishlistImg from "../../assets/media/wishlist.png";
import { Link } from "react-router-dom";

const MyWishlist = () => {
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    apiClient
      .get(`/user/${user}`)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [user]);

  useEffect(() => {
    const fetchWishlistData = async () => {
      try {
        const response = await apiClient.get(`/user/${user}`);
        const userData = response.data.data;
        if (userData && userData.wishlist) {
          const itemsPromises = userData.wishlist.map((item) => {
            return apiClient.get(`/product/${item.product_ids}`);
          });

          const productsResponse = await Promise.all(itemsPromises);
          const wishlistWithProductDetails = userData.wishlist.map(
            (wishlistItem, index) => {
              return {
                ...wishlistItem,
                product: productsResponse[index].data,
              };
            }
          );

          setWishlistItems(wishlistWithProductDetails);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchWishlistData();
  }, [user, wishlistItems]);

  const handleAddToCart = (product) => {
    if (product && product.varients && product.varients.length > 0) {
      if (product.varients.length >= 1) {
        addToCart(product, 1, product.varients[0]);
      }
    } else {
      toast.error("Product or its variants are not available.");
    }
  };

  const handleRemoveFromWishlist = async (w_id) => {
    try {
      await apiClient.delete(`/wishlist-remove/${w_id}`);
      setWishlistItems(wishlistItems.filter((item) => item.w_id !== w_id));
      toast.success("Item removed from wishlist successfully!");
    } catch (error) {
      toast.error("Failed to remove item from wishlist. Please try again.");
    }
  };

  return (
    <section>
      <div className="row">
        <div className="col-lg-12">
          <div className="card border shadow-0">
            <div className="m-4 table-responsive text-center">
              {wishlistItems.length === 0 ? (
                <>
                  <img
                    className="rounded img-fluid m-4"
                    style={{ opacity: 0.1 }}
                    src={wishlistImg}
                  />

                  <p className="text-center lead ">Your wishlist is empty.</p>
                  <Link
                    to="/shop/"
                    className="btn btn-info border-0  m-1 text-white"
                    style={{ backgroundColor: "#800080" }}
                  >
                    EXPLORE MORE PRODUCTS
                  </Link>
                </>
              ) : (
                <>
                  <table className="table text-center table-striped">
                    <thead>
                      <tr className="m-3">
                        <th>Product Image</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>In Stock</th>
                        <th>Total Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {isLoading && (
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      )}

                      {wishlistItems.map((wishlistItem, index) => (
                        <tr className="align-middle" key={index}>
                          <td>
                            <img
                              className="border rounded"
                              style={{ width: "96px", height: "96px" }}
                              src={`${IMAGE_BASE_URL}/${wishlistItem.products.product_image}`}
                              alt={wishlistItem.product.alt_content}
                            />
                          </td>
                          <td>{wishlistItem.products.product_name}</td>
                          <td>
                            {wishlistItem.products &&
                              wishlistItem.products.varients &&
                              wishlistItem.products.varients[0].final_price}
                          </td>
                          <td>
                            {wishlistItem.products &&
                              wishlistItem.products.varients &&
                              wishlistItem.products.varients[0]
                                .available_quantity}
                          </td>
                          <td>
                            <button
                              className="btn btn-danger border-0 btn-sm m-1 text-white"
                              type="button"
                              style={{ backgroundColor: "#800080" }}
                              onClick={() =>
                                handleAddToCart(
                                  wishlistItem.products,
                                  1,
                                  wishlistItem.products.varients[0]
                                )
                              }
                            >
                              Add to Cart
                            </button>
                            <button
                              className="btn btn-danger btn-sm m-1"
                              type="button"
                              onClick={() =>
                                handleRemoveFromWishlist(wishlistItem.w_id)
                              }
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyWishlist;

import React, { useState, useContext, useEffect } from "react";
import "./CheckoutPage.css";
import PageHeader from "../Common/PageHeader";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import CartContext from "../../contexts/CartContext";
import { toast } from "react-toastify";
import apiClient from "../../utils/api-client";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import UserContext from "../../contexts/UserContext";

const CheckoutPage = () => {
  const [subTotal, setSubTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [shippingCharges, setShippingCharges] = useState(0);
  const [defaultShippingAddress, setDefaultShippingAddress] = useState([]);
  const { cart, clearCart } = useContext(CartContext);
  const [promo_code, setPromo_code] = useState("");
  const [promoData, setPromoData] = useState(null);
  const [cities, setCities] = useState([]);
  const [error, setError] = useState("");
  const { user } = useContext(UserContext);
  const [toastShown, setToastShown] = useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  const userEmail = localStorage.getItem("userEmail");
  const navigate = useNavigate();

  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    apiClient
      .get(`/default-shipping-address/${user}`)
      .then((res) => {
        setDefaultShippingAddress(res.data.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [user, cities]);

  useEffect(() => {
    apiClient
      .get("/city")
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    let total = 0;
    let weight = 0;
    cart.forEach((item) => {
      total += item.product.varients[0].final_price * item.quantity;
      weight += item.product.varients[0].product_weight * item.quantity;
    });
    setSubTotal(total);
    setTotalWeight(weight);
  }, [cart]);

  const totalOrderPrice = subTotal + shippingCharges;

  const getShippingCharges = async (cityId, weight) => {
    try {
      const response = await apiClient.post("/get-shipping-charges", {
        city_id_fk: cityId,
        wc_weight: weight,
      });
      const charges = parseInt(response.data.data.wc_charges);
      if (isNaN(charges)) {
        throw new Error("Invalid shipping charges received");
      }
      return charges;
    } catch (error) {
      return 0; // Set shipping charges to 0 in case of error
    }
  };

  useEffect(() => {
    const fetchShippingCharges = async () => {
      try {
        const cityId = user ? defaultShippingAddress.city_id_fk : watch("city");
        const charges = await getShippingCharges(cityId, totalWeight);

        if (subTotal < 3000) {
          setShippingCharges(charges);
        } else {
          setShippingCharges(0);
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to get shipping charges");
      }
    };

    fetchShippingCharges();
  }, [user, defaultShippingAddress, watch("city"), totalWeight, subTotal]);

  const onSubmit = async (data) => {
    let formData;
    if (user) {
      // If user is logged in, use default shipping address state value
      formData = {
        full_name: defaultShippingAddress.receiver_name,
        phone: defaultShippingAddress.receiver_phone,
        city_id_fk: defaultShippingAddress.city_id_fk,
        address: defaultShippingAddress.street_address,
        email: userEmail,
        order_instructions: data.order_notes,
        rider_instructions: data.rider_notes,
        products: cart.map(({ product, variant, quantity }) => ({
          product_id: product.product_id,
          variant_id: variant.p_v_id,
          color_id_fk: variant.color_id_fk,
          variant_price: variant.final_price,
          quantity: quantity,
        })),
        order_price: subTotal,
        transaction_amount: discountedTotal,
        shipping_charges: shippingCharges,
        discounted_value: savings,
        // receiver_phone: defaultShippingAddress.receiver_phone,
      };
    } else {
      // If user is not logged in, use form data
      formData = {
        full_name: data.full_name,
        phone: data.phone,
        city_id_fk: data.city,
        address: data.address,
        email: data.email,
        order_instructions: data.order_notes,
        rider_instructions: data.rider_notes,
        products: cart.map(({ product, variant, quantity }) => ({
          product_id: product.product_id,
          variant_id: variant.p_v_id,
          color_id_fk: variant.color_id_fk,
          variant_price: variant.final_price,
          quantity: quantity,
        })),
        order_price: subTotal,
        transaction_amount: discountedTotal,
        shipping_charges: shippingCharges,
        discounted_value: savings,
        // receiver_phone: data.phone,
      };
    }

    try {
      setIsPlacingOrder(true);
      await apiClient.post("/checkout", formData);
      toast.success("Order placed successfully!");
      clearCart();
      user ? navigate("/account/orders-history") : navigate("/");
    } catch (error) {
      toast.error("Failed to place order. Please try again later.");
      setIsPlacingOrder(false);
    } finally {
      setIsPlacingOrder(false);
    }
  };

  const promoSubmit = () => {
    if (!promo_code) {
      toast.error("Please enter a promo code");
      return;
    }

    apiClient
      .post("/promocode", { promo_code })
      .then((response) => {
        if (response.data.status === true) {
          setPromoData(response.data.data);
        } else {
          toast.error("Invalid promo code");
          setPromoData(null);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while applying promo code");
      });
  };

  const discountedTotal =
    promoData && subTotal >= promoData.order_value
      ? promoData.is_percentage
        ? shippingCharges +
          subTotal -
          (subTotal * promoData.discounted_value) / 100
        : shippingCharges + subTotal - promoData.discounted_value
      : +totalOrderPrice;

  const savings =
    promoData && subTotal >= promoData.order_value
      ? promoData.is_percentage
        ? (subTotal * promoData.discounted_value) / 100
        : promoData.discounted_value
      : 0;

  useEffect(() => {
    if (promoData && subTotal < promoData.order_value && !toastShown) {
      const amountRequired = promoData.order_value - subTotal;
      toast.info(`Add Rs.${amountRequired} more to apply the promo code.`);
      setToastShown(true);
    } else if (promoData && subTotal >= promoData.order_value && !toastShown) {
      toast.success(`Promo code applied successfully. You saved Rs.${savings}`);
      setToastShown(true);
    }
  }, [promoData, subTotal, savings, toastShown]);

  const defaultCity = defaultShippingAddress
    ? cities.find((city) => city.city_id === defaultShippingAddress.city_id_fk)
    : null;

  return (
    <>
      <Helmet>
        <title>
          Just Check out and Get your Unique Home Products on Doorstep
        </title>

        <meta
          name="keywords"
          content="new unique items,creative items for home,creative home decor items,creative decorative items,creative products to sell online,creative memories items for sale,creative gift supplies,creative item for home and office,unique products for home,unique home products,unique office wall decor,interesting home products,unique decorative pieces,unique home essentials,funky home decor items,unique home decorative items,unique home office decor,unique household gadgets"
        />
        <meta
          name="description"
          content="Don’t Get Late and Checkout Your Bill to get Unique Home Products. Our Delivery services are fast and quick that you don’t have to wait for a long time."
        />
      </Helmet>
      <PageHeader title="Checkout" />
      <div className="section py-5">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            {!user && (
              <div className="row mb-5">
                <div className="col-md-12">
                  <div
                    className="border p-4 rounded"
                    style={{ backgroundColor: "#f1f1f1" }}
                  >
                    Returning customer?
                    <Link
                      to="/login"
                      state={{ from: location.pathname }}
                      className="text-decoration-underline"
                    >
                      Click here
                    </Link>{" "}
                    to login
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col-md-7 mb-5 mb-md-0 pb-4 ">
                <h2 className="h3 mb-3 text-black">Billing Details</h2>

                <div className="p-3 p-lg-5 border">
                  <div className="form-group row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="full_name" className="mb-2">
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="full_name"
                        {...(user && {
                          value: defaultShippingAddress.receiver_name,
                        })}
                        readOnly={user}
                        {...register("full_name", { required: !user })}
                      />
                      {errors.full_name && (
                        <span className="text-danger">
                          Full name is required
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="phone" className="mb-2">
                        Phone <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        {...(user && {
                          value: defaultShippingAddress.receiver_phone,
                        })}
                        readOnly={user}
                        {...register("phone", { required: !user })}
                      />
                      {errors.phone && (
                        <span className="text-danger">Phone is required</span>
                      )}
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <div className="col-md-12">
                      <label htmlFor="email" className="mb-2">
                        Email Address <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        {...(user && {
                          value: userEmail,
                        })}
                        readOnly={user}
                        {...register("email", { required: !user })}
                      />
                      {!user && errors.email && (
                        <span className="text-danger">Email is required</span>
                      )}
                    </div>
                  </div>

                  {!user && (
                    <>
                      <div className="form-group mb-3">
                        <label htmlFor="city" className="mb-2">
                          Town/City <span className="text-danger">*</span>
                        </label>
                        <select
                          id="city"
                          className="form-control"
                          {...(user && { readOnly: true })}
                          {...(user &&
                            defaultCity && { value: defaultCity.city_id })}
                          readOnly={user}
                          {...register("city", { required: !user })}
                        >
                          <option value="">Select City</option>
                          {cities &&
                            cities.map((city) => (
                              <option key={city.city_id} value={city.city_id}>
                                {city.city_name}
                              </option>
                            ))}
                        </select>
                        {errors.city && (
                          <span className="text-danger">City is required</span>
                        )}
                      </div>

                      <div className="form-group row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="address" className="mb-2">
                            Address <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="address"
                            rows="3"
                            {...(user && {
                              value: defaultShippingAddress.street_address,
                            })}
                            readOnly={user}
                            {...register("address", { required: !user })}
                          ></textarea>
                          {errors.address && (
                            <span className="text-danger">
                              Address is required
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {user && (
                    <div className="form-group row mb-3">
                      <div className="col-md-12">
                        <div className=" card card-body my-2">
                          <p className="card-text">
                            <i className="fa-solid fa-location-dot fs-5 me-1" />{" "}
                            Shipping Address
                            <br />
                            {defaultShippingAddress.street_address} ,{" "}
                            {defaultCity ? defaultCity.city_name : ""} <br />
                            Name: {defaultShippingAddress.receiver_name}
                            <span className="ms-3" />
                            Phone: {defaultShippingAddress.receiver_phone}
                          </p>

                          <div className="align-middle">
                            <Link
                              to="/account/profile"
                              className="btn btn-primary btn-sm border-0 text-white"
                              style={{
                                backgroundColor: "#791a89",
                              }}
                            >
                              Change Address
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form-group mb-4">
                    <label htmlFor="order_notes" className="mb-2">
                      Instructions for the order (optional)
                    </label>
                    <textarea
                      className="form-control"
                      id="order_notes"
                      rows="3"
                      {...register("order_notes")}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label htmlFor="order_notes" className="mb-2">
                      Instructions for the rider (optional)
                    </label>
                    <textarea
                      className="form-control"
                      id="order_notes"
                      rows="3"
                      {...register("rider_notes")}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row mb-5">
                  <div className="col-md-12">
                    <h2 className="h3 mb-3 text-black">Coupon Code</h2>
                    <div className="p-3 p-lg-5 border">
                      <label htmlFor="c_code" className="text-black mb-3">
                        Enter your coupon code if you have one
                      </label>
                      <div className="input-group w-75">
                        <input
                          type="text"
                          className="form-control"
                          id="c_code"
                          placeholder="Coupon Code"
                          aria-label="Coupon Code"
                          aria-describedby="button-addon2"
                          value={promo_code}
                          onChange={(e) => setPromo_code(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary coupan"
                            type="button"
                            id="button-addon2"
                            onClick={promoSubmit}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-md-12">
                    <h2 className="h3 mb-3 text-black">Your Order</h2>
                    <div className="p-3 p-lg-5 border">
                      <table className="table site-block-order-table mb-5">
                        <thead>
                          <th className="">Products</th>
                          <th style={{ width: 90 }}>Total</th>
                        </thead>
                        <tbody>
                          {cart &&
                            cart.map(({ product, quantity, variant }) => (
                              <tr>
                                <td>
                                  {product.product_name} -
                                  <span
                                    style={{
                                      color: "#791a89",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {variant.p_v_name}
                                  </span>
                                  <strong className="mx-2">x</strong>({quantity}
                                  )
                                </td>
                                <td>
                                  Rs{" "}
                                  {product.varients[0].final_price * quantity}
                                </td>
                              </tr>
                            ))}
                          {cart.map(({ product, variant, quantity }) => (
                            <React.Fragment key={product.product_id}>
                              <input
                                type="hidden"
                                name={`products[${product.product_id}][product_id]`}
                                value={product.product_id}
                              />
                              <input
                                type="hidden"
                                name={`products[${product.product_id}][variant_id]`}
                                value={variant.variant_id}
                              />
                              <input
                                type="hidden"
                                name={`products[${product.product_id}][color_id_fk]`}
                                value={variant.color_id_fk}
                              />
                              <input
                                type="hidden"
                                name={`products[${product.product_id}][final_price]`}
                                value={variant.final_price}
                              />
                              <input
                                type="hidden"
                                name={`products[${product.product_id}][quantity]`}
                                value={quantity}
                              />
                            </React.Fragment>
                          ))}

                          <tr className="">
                            <td className=" text-black font-weight-bold ">
                              <strong>Cart Subtotal</strong>
                            </td>
                            <td className="text-black">Rs {subTotal}</td>
                          </tr>
                          <tr>
                            <td className="text-black font-weight-bold">
                              <strong>Shipping Charges</strong>
                            </td>
                            <td className="text-black">Rs {shippingCharges}</td>
                          </tr>
                          {/* <tr>
                            <td className="h5 text-black font-weight-bold">
                              <strong>Order Total</strong>
                            </td>
                            <td className="text-black font-weight-bold">
                              <strong>Rs {totalOrderPrice}</strong>
                            </td>
                          </tr> */}
                          {promoData && (
                            <tr>
                              <td className="text-black font-weight-bold">
                                <strong>You Saved</strong>
                              </td>
                              <td className="text-black">Rs {savings}</td>
                            </tr>
                          )}
                          <tr>
                            <td className="h5 text-black font-weight-bold">
                              <strong>Order Total</strong>
                            </td>
                            <td className="text-black font-weight-bold">
                              <strong>Rs {discountedTotal}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="form-group">
                        <button
                          className="btn btn-primary py-3 button place_order_btn"
                          // onClick={checkout}
                          disabled={isPlacingOrder}
                        >
                          {isPlacingOrder ? "Order Placing..." : "Place Order"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CheckoutPage;

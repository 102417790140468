import React from "react";
import "./QuantityInput.css";

const QuantityInput = ({
  quantity,
  setQuantity,
  cartPage,
  productId,
  variantId,
}) => {
  return (
    <>
      <div className="input-group mb-3 quantity col-md-6 w-md-100">
        <button
          className="btn btn-white border border-secondary px-3"
          type="button"
          id="button-addon1"
          data-mdb-ripple-color="dark"
          disabled={quantity <= 1}
          onClick={() => {
            cartPage
              ? setQuantity("decrease", productId, variantId)
              : setQuantity(quantity - 1);
          }}
        >
          <i className="fas fa-minus"></i>
        </button>
        <input
          type="text"
          className="form-control text-center border border-secondary"
          value={quantity}
          aria-label="Example text with button addon"
          aria-describedby="button-addon1"
          defaultValue
        />

        <button
          className="btn btn-white border border-secondary px-3"
          type="button"
          id="button-addon2"
          data-mdb-ripple-color="dark"
          disabled={quantity >= 30}
          onClick={() => {
            cartPage
              ? setQuantity("increase", productId, variantId)
              : setQuantity(quantity + 1);
          }}
        >
          <i className="fas fa-plus"></i>
        </button>
      </div>
    </>
  );
};

export default QuantityInput;

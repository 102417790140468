import React, { useContext, useState } from "react";
import "./ProductCard.css";
import "./VideoProductCard.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link, NavLink } from "react-router-dom";
import ProductVarientModal from "./ProductVariantModal";
import YouTube from "react-youtube"; // Import React YouTube component
import CartContext from "../../contexts/CartContext";
import UserContext from "../../contexts/UserContext";
import { toast } from "react-toastify";
import { addToWishlist } from "../../services/userServices";
import { IMAGE_BASE_URL } from "../../utils/api-client";

const ProductCard = ({ product, youtubeVideoUrl }) => {
  const [showVariantModal, setShowVariantModal] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [showVideo, setShowVideo] = useState(false);

  const id = product.product_id;
  const slug = product.product_slug;
  const subcat_slug = product.subcategory && product.subcategory.s_cat_slug;
  const title = product.product_name;
  const price =
    product.varients.length > 0 ? product.varients[0].product_price : "N/A";

  const image = product.product_image;
  const finalPrice =
    product.varients.length > 0 ? product.varients[0].final_price : "N/A";

  const stock = product.in_stock;
  const rating = product.avg_rating;
  const ratingCount = product.count_rating;

  const { addToCart } = useContext(CartContext);
  const { user } = useContext(UserContext);

  const product_ids = product.product_id;
  const user_id_fk = parseInt(user);

  const handleAddToWishlist = async () => {
    try {
      await addToWishlist({ user_id_fk, product_ids });
      toast.success("Product added to wishlist successfully!");
    } catch (error) {
      toast.error("Error adding product to wishlist:", error.message);
    }
  };

  const handleAddToCart = () => {
    if (product.varients.length === 1) {
      addToCart(product, 1, product.varients[0]);
    } else {
      setShowVariantModal(true);
    }
  };
  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  // Use touch events for mobile devices
  const touchStartHandler = () => {
    setShowVideo(true);
  };

  const touchEndHandler = () => {
    setShowVideo(false);
  };

  return (
    <section className=" card_container ">
      <div className="product_card">
        <div
          className="product_image youtube video"
          onMouseEnter={() => setShowVideo(true)}
          onMouseLeave={() => setShowVideo(false)}
          onTouchStart={touchStartHandler} // Mobile event
          onTouchEnd={touchEndHandler} // Mobile event
        >
          {showVideo ? (
            <div className="video-responsive">
              <YouTube videoId={youtubeVideoUrl} opts={opts} />
            </div>
          ) : (
            <NavLink to={`/shop/${subcat_slug}/${slug}/`}>
              <img
                src={`${IMAGE_BASE_URL}/${image}`}
                alt="product image"
                className="image-fluid"
              />
            </NavLink>
          )}
        </div>
        <div className="product_details">
          <h5 className="w-100 product_title">
            <NavLink
              to={`/shop/${subcat_slug}/${slug}/`}
              className="text-decoration-none text-black"
            >
              {title}
            </NavLink>
          </h5>
          <p className="align_center justify-content-between product_price_container mt-2">
            <span className="fs-15 product_price d-block">
              <sup>Rs</sup> {finalPrice}
              {finalPrice < price && (
                <sup className="ms-2 text-muted text-decoration-line-through">
                  <sup>Rs.</sup> {price}
                </sup>
              )}
            </span>
            <span className="fs-12 product_rating">
              <i className="fa fa-solid fa-star p-1">
                {" "}
                {rating == null ? 0 : rating}
              </i>
              <span className="product_rating_count">({ratingCount})</span>
            </span>
          </p>

          <div className="row ">
            <div className="col-12 align_center justify-content-between">
              <button
                type="button"
                href=""
                className="btn product_card_btn"
                onClick={handleAddToCart}
                disabled={stock < 1}
              >
                Add to cart
              </button>

              <Link className="card_wishlist" onClick={handleAddToWishlist}>
                <i className="fas fa-heart fa-lg px-1 text-secondary"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Render variant selection modal */}
      {showVariantModal && (
        <ProductVarientModal
          id={id}
          slug={slug}
          varients={product.varients}
          onClose={() => setShowVariantModal(false)}
          show={showVariantModal}
          onVariantSelect={(selectedVariant) => {
            addToCart(selectedVariant);
            setShowVariantModal(false);
          }}
          subcat_slug={subcat_slug}
          product={product}
        />
      )}
    </section>
  );
};

export default ProductCard;

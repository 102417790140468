import React from "react";
import "./PageHeader.css";
import { Link } from "react-router-dom";

const PageHeader = ({ title, url }) => {
  return (
    <section className="page_header_section set_bg mt-2">
      <div className="container-fluid px-5">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="page_header_text">
              <h2 className="text-white">{title}</h2>
              <div className="page_header_option">
                <Link to="/" className="text-white fw-600">
                  Home
                </Link>
                <span>{!url && title}</span>
                <span>{url && url}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHeader;

import React from "react";
import "../About/AboutPage.css";
import PageHeader from "../Common/PageHeader";
import aboutImg from "../../assets/media/about-us-img.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>What Unique Home Products do We Offer for Our Customers? </title>
        <meta
          name="keywords"
          content="creative items,unique items,creative items to sell,creative craft items,creative gift items,creative home decor products,unique home accessories,best home decor gifts,unique kitchen decor,unique modern home decor,unique decorative items,unique house accessories,cheap home decor gifts,unique kitchen products,unique kitchenware itemsunique kitchen items,home and kitchen unique products,unique kitchen accessories,unique kitchen tools"
        />
        <meta
          name="description"
          content="Discover a range of unique home products for your house. We at Bakumia have a wide variety of everyday products available at low prices for your home"
        />
      </Helmet>
      <PageHeader title="About Page" />
      <section className="">
        <div className="container-fluid p-5">
          <div className="row ">
            <div className="col-md-12 mt-5 ">
              <h2 className="display-6 text-center fw-medium">Who Are We?</h2>
              <hr className="hr mb-4" />
              <div className="text-muted lh-lg text-center mb-5">
                <p>
                  At Bakumia, we have top-notch products that help enhance your
                  house’s outlook. Explore our store’s creative and{" "}
                  <b>unique items</b> that make each feature in your house stand
                  out.
                </p>
                <p>
                  Our range of vast kitchen tools,
                  <b>unique decorative items</b> with unique modern home decor
                  products will amplify every corner in your house and add a
                  story to it.
                </p>
                <p>
                  We have creative items to sell to you that have the best
                  quality. Our products make the <b>best home decor gifts</b>{" "}
                  for your loved ones too if you’re looking for something unique
                  and meaningful to gift.
                </p>
              </div>
            </div>

            <div className="col-md-12 mt-5 ">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-6 col-sm-12">
                  <div className="banner-fluid-image">
                    <img src={aboutImg} alt className="w-100" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <h2 className="display-6 text-center fw-medium pt-3">
                    Who Are We?
                  </h2>
                  <hr className="hr mb-4" />
                  <div className="text-muted lh-lg text-center mb-5">
                    <p>
                      From creative home decor products to{" "}
                      <b>unique home accessories</b>, we have got it all
                      covered. Browse through our endless unique decorative
                      items with a touch of sophistication and modernity while
                      you enjoy decorating your beautiful house.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-5 lh-lg">
              <h2 className="display-6 text-center fw-medium">
                Our best products
              </h2>
              <hr className="hr mb-4" />

              <div className="row justify-content-center mt-4">
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <div className="card border-0">
                    <div className="card-body">
                      <h3 className="card-title">• Home Decor items </h3>
                      <p className="card-text">
                        Browse through the endless options at Bakumia to add a
                        splash of modernity to your home with creative{" "}
                        <b>home decor products</b>. The unique home accessories
                        at our store have the cheapest prices so you can redo
                        your home without spending too much.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-6 mb-lg-15 mb-13">
                  <div className="card border-0">
                    <div className="card-body">
                      <h3 className="card-title">• Kitchen Decor</h3>
                      <p className="card-text">
                        Enhance your working space with{" "}
                        <b>unique kitchen tools</b> and accessories to help
                        yourself and make your workspace beautiful to look at.
                        Choose through our unique kitchenware items and add them
                        to your basket.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <div className="card border-0">
                    <div className="card-body">
                      <h3 className="card-title">• Gifts</h3>
                      <p className="card-text">
                        Explore our unique products to serve as gifts for your
                        loved ones. Our creative and unique home items serve as
                        the <b>best home decor gifts</b> that amplify the look
                        of anyone’s home.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <div className="card border-0">
                    <div className="card-body">
                      <h3 className="card-title">
                        Interested to buy something ?
                      </h3>

                      <p className="card-text">
                        <Link
                          to="/shop"
                          className="btn-link with-icon"
                          style={{ color: "#791a89 " }}
                        >
                          Order now
                          <i
                            className="fa fa-arrow-right ms-3"
                            style={{ fontSize: 20 }}
                          />
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPage;

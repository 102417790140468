import React, { useEffect, useState, useRef, useCallback } from "react";
import ProductCard from "./ProductCard";
import apiClient from "../../utils/api-client";
import CardSkeleton from "../Common/CardSkeleton";
import Loader from "../Common/Loader";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [sortCriteria, setSortCriteria] = useState("0");
  const productContainerRef = useRef(null);

  const fetchProducts = useCallback(
    async (currentPage) => {
      console.log(`Fetching page: ${currentPage}`); // Debug log
      try {
        setIsFetching(true); // Set fetching status to true when request starts
        const res = await apiClient.post("/products", { page: currentPage });
        const { data: newProducts, total_pages } = res.data;

        setProducts((prevProducts) => {
          const uniqueNewProducts = newProducts.filter(
            (newProduct) =>
              !prevProducts.some(
                (product) => product.product_id === newProduct.product_id
              )
          );
          const updatedProducts = [...prevProducts, ...uniqueNewProducts];
          setDisplayedProducts(sortProducts(updatedProducts)); // Update displayed products after sorting
          return updatedProducts;
        });

        setTotalPages(total_pages); // Update total pages from response
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      } finally {
        setIsFetching(false); // Set fetching status to false when request completes
      }
    },
    [sortCriteria]
  );

  useEffect(() => {
    fetchProducts(page);
  }, [fetchProducts, page]);

  useEffect(() => {
    setDisplayedProducts(sortProducts([...products]));
  }, [sortCriteria, products]);

  const handleScroll = useCallback(() => {
    if (
      !isFetching && // Check if not already fetching
      productContainerRef.current &&
      productContainerRef.current.getBoundingClientRect().bottom <=
        window.innerHeight + 100 &&
      page < totalPages
    ) {
      console.log(`Adding page: ${page + 1} to queue`); // Debug log
      setPage((prevPage) => prevPage + 1);
    }
  }, [isFetching, page, totalPages]);

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [handleScroll]);

  // Debounce function to limit the rate of function execution
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const sortProducts = (products) => {
    switch (sortCriteria) {
      case "featured":
        return products.sort((a, b) => b.is_featured - a.is_featured);
      case "new_arrival":
        return products.sort(
          (a, b) => new Date(b.time_stamp) - new Date(a.time_stamp)
        );
      case "biggest_discount":
        return products.sort((a, b) => {
          const maxDiscountA = Math.max(
            ...a.varients.map((v) => v.discount_percent)
          );
          const maxDiscountB = Math.max(
            ...b.varients.map((v) => v.discount_percent)
          );
          return maxDiscountB - maxDiscountA;
        });
      case "low_to_high":
        return products.sort((a, b) => {
          const minPriceA = Math.min(...a.varients.map((v) => v.final_price));
          const minPriceB = Math.min(...b.varients.map((v) => v.final_price));
          return minPriceA - minPriceB;
        });
      case "high_to_low":
        return products.sort((a, b) => {
          const minPriceA = Math.min(...a.varients.map((v) => v.final_price));
          const minPriceB = Math.min(...b.varients.map((v) => v.final_price));
          return minPriceB - minPriceA;
        });
      default:
        return products;
    }
  };

  return (
    <>
      <div className="filter__item">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="filter__sort">
              <span>Sort By</span>
              <select
                className="sorted_by ms-2"
                name="sorted_by"
                onChange={handleSortChange}
              >
                <option value="0">Default</option>
                <option value="featured">Featured</option>
                <option value="new_arrival">New Arrival</option>
                <option value="biggest_discount">Biggest Discount</option>
                <option value="low_to_high">Price: Low to High</option>
                <option value="high_to_low">Price: High to Low</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 text-right">
            <div className="">
              <span>Products: {products.length}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center" ref={productContainerRef}>
        {error && <em className="form_error">{error}</em>}
        {isLoading && <CardSkeleton cards={8} />}
        {displayedProducts &&
          displayedProducts.map((product) => (
            <div
              className="col-lg-3 col-md-4 col-sm-6 col-6 p-2"
              key={product.product_id}
            >
              <ProductCard product={product} />
            </div>
          ))}
        {isFetching && <Loader />}
      </div>
    </>
  );
};

export default ProductList;

// import React, { useEffect, useState, useRef, useCallback } from "react";
// import ProductCard from "./ProductCard";
// import apiClient from "../../utils/api-client";
// import CardSkeleton from "../Common/CardSkeleton";

// const ProductList = () => {
//   const [products, setProducts] = useState([]);
//   const [displayedProducts, setDisplayedProducts] = useState([]);
//   const [error, setError] = useState("");
//   const [isLoading, setIsLoading] = useState(true);

//   const [isLoadingMore, setIsLoadingMore] = useState(false);
//   const [sortCriteria, setSortCriteria] = useState("0");
//   const [itemsToShow, setItemsToShow] = useState(8);

//   useEffect(() => {
//     apiClient
//       .get("/products")
//       .then((res) => {
//         setProducts(res.data.data);
//         setDisplayedProducts(res.data.data.slice(0, itemsToShow));
//         setIsLoading(false);
//       })
//       .catch((err) => {
//         setError(err.message);
//         setIsLoading(false);
//       });
//   }, []);

//   useEffect(() => {
//     setDisplayedProducts((prevProducts) => sortProducts([...prevProducts]));
//   }, [sortCriteria]);

//   useEffect(() => {
//     if (!isLoading && itemsToShow < products.length) {
//       setDisplayedProducts(products.slice(0, itemsToShow));
//     }
//   }, [itemsToShow]);

//   const handleSortChange = (event) => {
//     setSortCriteria(event.target.value);
//   };

//   const sortProducts = (products) => {
//     switch (sortCriteria) {
//       case "featured":
//         return products.sort((a, b) => b.is_featured - a.is_featured);
//       case "new_arrival":
//         return products.sort(
//           (a, b) => new Date(b.time_stamp) - new Date(a.time_stamp)
//         );
//       case "biggest_discount":
//         return products.sort((a, b) => {
//           const maxDiscountA = Math.max(
//             ...a.varients.map((v) => v.discount_percent)
//           );
//           const maxDiscountB = Math.max(
//             ...b.varients.map((v) => v.discount_percent)
//           );
//           return maxDiscountB - maxDiscountA;
//         });
//       case "low_to_high":
//         return products.sort((a, b) => {
//           const minPriceA = Math.min(...a.varients.map((v) => v.final_price));
//           const minPriceB = Math.min(...b.varients.map((v) => v.final_price));
//           return minPriceA - minPriceB;
//         });
//       case "high_to_low":
//         return products.sort((a, b) => {
//           const minPriceA = Math.min(...a.varients.map((v) => v.final_price));
//           const minPriceB = Math.min(...b.varients.map((v) => v.final_price));
//           return minPriceB - minPriceA;
//         });
//       default:
//         return products;
//     }
//   };

//   const observer = useRef();

//   const lastProductElementRef = useCallback(
//     (node) => {
//       if (isLoading) return;
//       if (observer.current) observer.current.disconnect();
//       observer.current = new IntersectionObserver((entries) => {
//         if (entries[0].isIntersecting && itemsToShow < products.length) {
//           setIsLoadingMore(true);
//           setTimeout(() => {
//             setItemsToShow((prevItemsToShow) => prevItemsToShow + 8);
//             setIsLoadingMore(false);
//           }, 1000); // Simulate network delay for loading more products
//         }
//       });
//       if (node) observer.current.observe(node);
//     },
//     [isLoading, itemsToShow, products.length]
//   );

//   const handleScroll = useCallback(() => {
//     if (
//       window.innerHeight + document.documentElement.scrollTop >=
//       document.documentElement.offsetHeight * 0.99
//     ) {
//       if (itemsToShow < products.length && !isLoadingMore) {
//         setIsLoadingMore(true);
//         setTimeout(() => {
//           setItemsToShow((prevItemsToShow) => prevItemsToShow + 8);
//           setIsLoadingMore(false);
//         }, 1000); // Simulate network delay for loading more products
//       }
//     }
//   }, [isLoadingMore, itemsToShow, products.length]);

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [handleScroll]);

//   return (
//     <>
//       <div className="filter__item">
//         <div className="row">
//           <div className="col-lg-6 col-md-6">
//             <div className="filter__sort">
//               <span>Sort By</span>
//               <select
//                 className="sorted_by ms-2"
//                 name="sorted_by"
//                 onChange={handleSortChange}
//               >
//                 <option value="0">Default</option>
//                 <option value="featured">Featured</option>
//                 <option value="new_arrival">New Arrival</option>
//                 <option value="biggest_discount">Biggest Discount</option>
//                 <option value="low_to_high">Price: Low to High</option>
//                 <option value="high_to_low">Price: High to Low</option>
//               </select>
//             </div>
//           </div>
//           <div className="col-lg-6 col-md-6 text-right">
//             <div className="">
//               <span>Products: {products.length}</span>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="row">
//         {error && <em className="form_error">{error}</em>}
//         {isLoading && <CardSkeleton cards={8} />}
//         {displayedProducts &&
//           displayedProducts.map((product, index) => (
//             <div
//               className="col-lg-3 col-md-4 col-sm-6 col-6 p-2"
//               key={product.product_id}
//               ref={
//                 index === displayedProducts.length - 1
//                   ? lastProductElementRef
//                   : null
//               }
//             >
//               <ProductCard product={product} />
//             </div>
//           ))}
//       </div>
//     </>
//   );
// };

// export default ProductList;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import "./BlogItem.css";
import CardSkeleton from "../Common/CardSkeleton";

const BlogItem = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiClient
      .get("/blog")
      .then((res) => {
        setBlogs(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, []);

  console.log("All Blogs:", blogs);
  return (
    <>
      {error && <em className="form_error">{error}</em>}
      {isLoading && <CardSkeleton cards={8} />}
      {blogs &&
        blogs.map((blog) => (
          <div className="col" key={blog.id}>
            <div className="card ">
              <Link to={`/blog-detail/${blog.b_slug}/`}>
                <img
                  src={`${IMAGE_BASE_URL}/${blog.b_image}`}
                  className="card-img-top"
                  alt="Hollywood Sign on The Hill"
                />
              </Link>
              <div className="card-body">
                <span className="text-muted">
                  <i class="fa-regular fa-calendar-check date-icon"></i>{" "}
                  {blog.b_datetime}
                </span>
                <Link to={`/blog-detail/${blog.b_slug}/`}>
                  <h5 className="card-title mb-3">{blog.b_title}</h5>
                </Link>
                {/* <p
            className="card-text text-muted"
            dangerouslySetInnerHTML={{
              __html: blog.b_short_desc, // Use shortDescription instead of product.product_details
            }}
          ></p> */}
                <Link
                  to={`/blog-detail/${blog.b_slug}`}
                  className="button text-white"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default BlogItem;

import React, { Suspense } from "react";

import { Route, Routes } from "react-router-dom";

import Home from "./components/Home/HomePage";
import BlogPage from "./components/Blog/BlogPage";
import ShopPage from "./components/Shop/ShopPage";
import ContactPage from "./components/Contact/ContactPage";
import VideosPage from "./components/Videos/VideosPage";
import SingleProductPage from "./components/Shop/SingleProductPage";
import LoginPage from "./components/Authentication/LoginPage";
import CartPage from "./components/Cart/CartPage";
import RegisterPage from "./components/Authentication/RegisterPage";
import NotFoundPage from "./components/NotFound/NotFoundPage";
import CheckoutPage from "./components/Checkout/CheckoutPage";
import WishlistPage from "./components/Wishlish/WishlistPage";
import SingleBlogPage from "./components/Blog/SingleBlogPage";
import UserAccount from "./components/UserAccount/UserAccount";
import AccountMain from "./components/UserAccount/AccountMain";
import OrdersHistory from "./components/UserAccount/OrdersHistory";
import ProfileSetting from "./components/UserAccount/ProfileSetting";
import MyWishlist from "./components/UserAccount/MyWishlist";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ReturnPolicy from "./components/ReturnPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import Faqs from "./components/Faqs";
import AboutPage from "./components/About/AboutPage";
import CategoryPage from "./components/Shop/CategoryPage";
import SearchResults from "./components/SearchResults";
import Logout from "./components/Authentication/Logout";
import OrderDetails from "./components/UserAccount/OrderDetails";
import CategoryProductList from "./components/Shop/CategoryProductsList";
import ProtectedRoute from "./ProtectedRoute";
import Sitemap from "./components/Sitemap";

const AllRouting = () => {
  return (
    <Suspense fallback={<div className="container">Loading...</div>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shop/" element={<ShopPage />} />
        {/* <Route
          exact
          path="/:category_slug/:s_cat_slug"
          element={<CategoryProductList />}
        /> */}
        <Route
          exact
          path="/shop/:subcat_slug/:product_slug/"
          element={<SingleProductPage />}
        />
        <Route path="/:slug" element={<CategoryPage />} />
        {/* Route for category with subcategory */}
        <Route path="/:slug/:subSlug/" element={<CategoryPage />} />
        <Route path="/blogs/" element={<BlogPage />} />
        <Route path="/blog-detail/:b_slug" element={<SingleBlogPage />} />
        <Route path="/contact/" element={<ContactPage />} />
        <Route path="/videos/" element={<VideosPage />} />
        <Route path="/login/" element={<LoginPage />} />
        <Route path="/register/" element={<RegisterPage />} />
        <Route path="/cart/" element={<CartPage />} />
        <Route path="/wishlist/" element={<WishlistPage />} />
        <Route path="/checkout/" element={<CheckoutPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/order-detail/:orderId/" element={<OrderDetails />} />
          <Route path="/account/" element={<UserAccount />}>
            <Route path="profile/" element={<AccountMain />} />
            <Route path="orders-history/" element={<OrdersHistory />} />
            <Route path="my-wishlist/" element={<MyWishlist />} />
            <Route path="profile-setting/" element={<ProfileSetting />} />
            <Route path="logout/" element={<Logout />} />
          </Route>
        </Route>
        <Route path="/about/" element={<AboutPage />} />
        <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
        <Route path="/return-policy/" element={<ReturnPolicy />} />
        <Route path="/terms-and-conditions/" element={<TermsAndConditions />} />
        <Route path="/faqs/" element={<Faqs />} />
        <Route path="/sitemap/" element={<Sitemap />} />
        <Route path="/search-results/" element={<SearchResults />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>

    // <Routes>
    //   <Route path="/" element={<Home />} />
    //   <Route path="/shop/" element={<ShopPage />} />
    //   <Route
    //     path="/:subcat_slug/:product_slug"
    //     element={<SingleProductPage />}
    //   />
    //   <Route path="/:slug" element={<CategoryPage />} />
    //   <Route path="/:category_slug/:slug" element={<CategoryProductList />} />
    //   <Route path="/blogs/" element={<BlogPage />} />
    //   <Route path="/blog-detail/:b_slug" element={<SingleBlogPage />} />
    //   <Route path="/contact/" element={<ContactPage />} />
    //   <Route path="/videos/" element={<VideosPage />} />
    //   <Route path="/login/" element={<LoginPage />} />
    //   <Route path="/register/" element={<RegisterPage />} />
    //   <Route path="/cart" element={<CartPage />} />
    //   <Route path="/wishlist/" element={<WishlistPage />} />
    //   <Route path="/checkout/" element={<CheckoutPage />} />
    //   <Route element={<ProtectedRoute />}>
    //     <Route path="/order-detail/:orderId" element={<OrderDetails />} />
    //     <Route path="/account/" element={<UserAccount />}>
    //       <Route path="profile/" element={<AccountMain />} />
    //       <Route path="orders-history/" element={<OrdersHistory />} />
    //       <Route path="my-wishlist/" element={<MyWishlist />} />
    //       <Route path="profile-setting/" element={<ProfileSetting />} />
    //       <Route path="logout" element={<Logout />} />
    //     </Route>
    //   </Route>
    //   <Route path="/about/" element={<AboutPage />} />
    //   <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
    //   <Route path="/return-policy/" element={<ReturnPolicy />} />
    //   <Route path="/terms-and-conditions/" element={<TermsAndConditions />} />
    //   <Route path="/faqs/" element={<Faqs />} />
    //   <Route path="/sitemap/" element={<Sitemap />} />
    //   <Route path="*" element={<NotFoundPage />} />
    //   <Route path="/search-results/" element={<SearchResults />} />
    // </Routes>
  );
};

export default AllRouting;

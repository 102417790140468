import React, { useEffect, useState } from "react";
import apiClient from "../utils/api-client";
import { NavLink } from "react-router-dom";
import PageHeader from "./Common/PageHeader";
import axios from "axios";

const Sitemap = () => {
  const [sitemap, setSitemap] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [products, setProducts] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    // Fetch sitemap data
    apiClient
      .get("/sitemap")
      .then((response) => {
        setSitemap(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setErrors(error.message);
        setLoading(false);
      });

    // Fetch products data
    apiClient
      .get("/products")
      .then((response) => {
        setProducts(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });

    // Fetch subcategories data
    axios
      .get("https://api.bakumia.pk/backend-api/get-product-sub-cate")
      .then((response) => {
        setSubcategories(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors}</div>;
  }

  const isProductLink = (url) => {
    const parts = url.split("/");
    if (parts.length === 3 && subcategories && products) {
      const [subcategorySlug, productSlug] = parts.slice(0, 2); // Take the first two parts
      return (
        products.some((product) => product.product_slug === productSlug) &&
        subcategories.some(
          (subcategory) => subcategory.s_cat_slug === subcategorySlug
        )
      );
    }
    return false;
  };

  return (
    <>
      <PageHeader title="Bakumia Sitemap" url="Sitemap" />
      <div className="container p-5">
        <div className="col-md-11 m-auto">
          <div className="row">
            {sitemap.map((item) => (
              <div className="col-md-6 my-2 text-dark" key={item.s_id}>
                <li>
                  <NavLink
                    to={
                      isProductLink(item.s_url)
                        ? `/shop/${item.s_url}`
                        : `/${item.s_url}`
                    }
                    className="text-dark "
                    style={{ fontSize: 20 }}
                  >
                    {item.s_title}
                  </NavLink>
                </li>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sitemap;

import React from "react";
import Skeleton from "react-loading-skeleton";

const CardSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((index) => (
      <section
        key={index}
        className="card_container  col-lg-3 col-md-4 col-sm-6 col-6 p-2"
      >
        <div className="product_card">
          <Skeleton height={210} />

          <div className="product_details">
            <h5 className="w-100 product_title">
              <Skeleton count={6} />
            </h5>
            <Skeleton count={1} />
          </div>
        </div>
      </section>
    ));
};

export default CardSkeleton;

import React, { useEffect, useState } from "react";
import "./NavLinks.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import apiClient from "../../utils/api-client";
import Dropdown from "react-bootstrap/Dropdown";

const NavLinks = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    apiClient
      .get("/category")
      .then((res) => {
        setCategories(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="container-fluid mt-2 col-md-12">
      <div className="row">
        <div className="col-lg-3 col-md-12">
          <Dropdown className="dropdown">
            <Dropdown.Toggle
              className="align_center dropdown_section btn btn-secondary  justify-content-between"
              type="button"
            >
              <FontAwesomeIcon icon={faBars} className="pe-4" />
              <span>All Categories</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {categories &&
                categories.map((category, index) => (
                  <Dropdown.Item className="li" key={index}>
                    <Link
                      to={`/${category.slug}/`}
                      className="dropdown-item p-0"
                    >
                      {category.p_category_name}
                    </Link>
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* <!-- Nav Links --> */}
        <div className="col-md-9 nav_links d-none d-lg-block">
          <ul className="nav justify-content-end ">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link " to="/shop/">
                Shop
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/blogs/">
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact/">
                Contact
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/videos/">
                Videos
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavLinks;

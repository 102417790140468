import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import UserContext from "../../contexts/UserContext";
import apiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import { Link, NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import orderHistoryImg from "../../assets/media/order-history.png";

const OrdersHistory = () => {
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState([]);
  const [error, setError] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchData(); // Fetch initial data
  }, [user]);

  const fetchData = () => {
    apiClient
      .get(`/user/${user}`)
      .then((res) => {
        setUserData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleCancelOrder = (orderId) => {
    setSelectedOrderId(orderId); // Set the selected order ID when Cancel Order button is clicked
    setIsModalOpen(true); // Open the modal
  };

  const onSubmit = async (data) => {
    const formData = {
      comment: data.reason,
      order_id: selectedOrderId,
    };

    try {
      await apiClient.post(`/order-cancel`, formData);
      toast.success("Order cancelled successfully!");
      setIsModalOpen(false);
      fetchData();
    } catch (error) {
      toast.error("Failed to cancel order. Please try again later.");
    } finally {
      setSelectedOrderId(null);
    }
  };

  return (
    <div className="p-4">
      <div className="mb-5">
        <h2 className="text-center">Order History</h2>
        <hr className="hr" />
        <p className="text-center mt-3 px-5">
          Kindly check your Order History and recheck purchased products detail.
          Verify your order details, Shipping Address, and check the status of
          your order to enjoy happy shopping.
        </p>
      </div>

      <div className="table-responsive text-center">
        {userData && userData.order && userData.order.length == 0 ? (
          <>
            <img
              className="rounded img-fluid m-4 "
              style={{ opacity: 0.1, width: 190 }}
              src={orderHistoryImg}
            />

            <p className="text-center lead">No orders found. </p>
            <Link
              to="/shop/"
              className="btn btn-info border-0  m-1 text-white"
              style={{ backgroundColor: "#800080" }}
            >
              EXPLORE MORE PRODUCTS
            </Link>
          </>
        ) : (
          <>
            <table className="table text-center table-striped">
              <thead>
                <tr className="m-3">
                  <th>Shipping Address</th>
                  <th>Shipping Charges</th>
                  <th>Order Price</th>
                  <th>Discount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {isLoading && (
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              )}

              <tbody>
                {userData &&
                  userData.order &&
                  userData.order.length > 0 &&
                  userData.order.map((orderItem, index) => (
                    <tr className="align-middle" key={index}>
                      <td>{orderItem.shipping_address}</td>
                      <td>Rs {orderItem.shipping_charges}</td>
                      <td>Rs {orderItem.order_price}</td>
                      <td>Rs {orderItem.discounted_value || 0}</td>
                      <td>
                        <span className="badge bg-warning text-dark p-2 m-1">
                          {orderItem.status.status}
                        </span>
                      </td>
                      <td>
                        {orderItem.status.status === "Pending" && (
                          <button
                            className="btn btn-danger btn-sm m-1"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target={`#cancelOrder-${orderItem.order_id}`}
                            onClick={() =>
                              handleCancelOrder(orderItem.order_id)
                            }
                          >
                            Cancel Order
                          </button>
                        )}
                        <div
                          className="modal fade"
                          id={`cancelOrder-${orderItem.order_id}`}
                          tabIndex={-1}
                          role="dialog"
                          aria-labelledby={`cancelOrder-label-${orderItem.order_id}`}
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="modal-body">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label className="h5 p-1 mb-4">
                                        Why did you choose to cancel this order?
                                      </label>
                                      <input
                                        type="hidden"
                                        name="order_id"
                                        value={selectedOrderId}
                                        {...register("order_id")}
                                      />
                                      <textarea
                                        className="form-control"
                                        id={`reason-${orderItem.order_id}`}
                                        rows="3"
                                        {...register("reason", {
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="submit"
                                    className="btn text-white"
                                    style={{ backgroundColor: "#800080" }}
                                    data-bs-dismiss="modal"
                                  >
                                    Cancel Order
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={() => setIsModalOpen(false)} // Close the modal
                                  >
                                    Close
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <NavLink
                          to={`/order-detail/${orderItem.order_id}`}
                          className="btn text-white btn-sm m-1"
                          style={{ backgroundColor: "#800080" }}
                        >
                          Order Details
                        </NavLink>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default OrdersHistory;

import React, { useEffect, useState } from "react";
import "./ShopPage.css";
import PageHeader from "./../Common/PageHeader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./ProductSidebar.css";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import CategoryProductList from "./CategoryProductsList";
import CardSkeleton from "../Common/CardSkeleton";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

const CategoryPage = () => {
  const navigate = useNavigate();
  const { slug, subSlug } = useParams();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubcategoryProducts, setSelectedSubcategoryProducts] =
    useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResponse, categoriesResponse] = await Promise.all([
          apiClient.get(`/category/${slug}`),
          apiClient.get("/category"),
        ]);
        setSelectedCategory(categoryResponse.data.data);
        setCategories(categoriesResponse.data.data);
        setIsLoading(false);
        setSelectedSubcategoryProducts(null);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
    if (selectedSubcategory !== null) {
      apiClient
        .get(`/subcategory/${selectedSubcategory}`)
        .then((res) => {
          setSelectedSubcategoryProducts(res.data.data);
          setLoadingProducts(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoadingProducts(false);
        });
    } else {
      setLoadingProducts(false);
    }
  }, [selectedSubcategory]);

  const handleSubcategoryClick = (subcategorySlug) => {
    setSelectedSubcategory(subcategorySlug);
    navigate(`/${slug}/${subcategorySlug}/`);
  };

  const productCounts =
    selectedSubcategoryProducts && selectedSubcategoryProducts.products
      ? selectedSubcategoryProducts.products.length
      : selectedCategory.products
      ? selectedCategory.products.length
      : 0;

  return (
    <>
      <Helmet>
        <title>{selectedCategory.meta_title}</title>
        <meta name="description" content={selectedCategory.meta_desc} />
        <meta name="keywords" content={selectedCategory.keywords} />
        {/* Add more meta tags if needed */}
      </Helmet>
      <PageHeader title="Shop" />
      <section className="append_products">
        <div className="container-fluid p-5">
          <div className="row ">
            <div className="col-lg-3 col-md-3  d-lg-block d-none position-relative">
              <div className="sidebar sticky-top">
                <div className="sidebar_item">
                  <ul className="ul">
                    <h4>Categories</h4>
                    {error && <em className="form_error">{error}</em>}
                    {isLoading && <Skeleton count={8} />}
                    {categories &&
                      categories.map((category, index) => (
                        <li className="li" key={index}>
                          <NavLink
                            to={`/${category.slug}/`}
                            className={`cat_products ${
                              slug === category.slug ? "active" : ""
                            }`}
                            onClick={() => {
                              setSelectedSubcategory(null);
                              setSelectedCategory(category);
                            }}
                          >
                            {category.p_category_name}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12 ">
              {/* <div className="filter__item">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="filter__sort">
                      <span>Sort By</span>
                      <select className="sorted_by ms-2" name="sorted_by">
                        <option value="0">Default</option>
                        <option value="featured">Featured</option>
                        <option value="new_arrival">New Arrival</option>
                        <option value="biggest_discount">
                          Biggest Discount
                        </option>
                        <option value="low_to_high">Price: Low to High</option>
                        <option value="high_to_low">Price: High to Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 text-right">
                    <div className="">
                      <span>Products: {productCounts}</span>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="row ps-1 mx-1 overflow-hidden">
                {/*  subcategory list */}
                {selectedCategory.subcategory &&
                  selectedCategory.subcategory.length > 1 &&
                  selectedCategory.subcategory.map((subcategory, index) => (
                    <NavLink
                      key={index}
                      className="col-lg-2 col-md-2 col-4  py-1 pb-2  categories_list"
                      to={`/${slug}/${subcategory.s_cat_slug}`} // Updated NavLink to include both category and subcategory slugs
                      onClick={() =>
                        handleSubcategoryClick(subcategory.s_cat_slug)
                      }
                    >
                      <div className="d-flex flex-column justify-content-center flex-direction-coloumn">
                        <img
                          src={`${IMAGE_BASE_URL}/${subcategory.s_cat_image}`}
                          alt="category Image"
                          className="image-fluid mx-auto"
                        />
                        <h5 style={{ fontSize: 12, fontWeight: 400 }}>
                          {subcategory.p_sub_category_name}{" "}
                        </h5>
                      </div>
                    </NavLink>
                  ))}
              </div>

              {loadingProducts && <CardSkeleton cards={4} />}
              {!loadingProducts && (
                <CategoryProductList
                  productsData={
                    selectedSubcategoryProducts
                      ? selectedSubcategoryProducts
                      : selectedCategory
                  }
                  isLoading={isLoading}
                  error={error}
                />
              )}
            </div>
          </div>

          <div className="col-12">
            <div className="col-md-12 mt-5 ">
              <h3 className="scat_subtitle">
                {selectedCategory.pcat_subtitle}
              </h3>
              <p className="scat_long_desc mb-0">
                <div
                  style={{ maxHeight: "250px", overflowY: "auto" }}
                  className="pt-3  "
                  dangerouslySetInnerHTML={{
                    __html: selectedCategory.pcat_short_desc,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryPage;

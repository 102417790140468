import React, { useState, useEffect, useContext } from "react";
import "./CartPage.css";
import PageHeader from "./../Common/PageHeader";
import { Link } from "react-router-dom";
import QuantityInput from "./../Shop/QuantityInput";
import CartContext from "../../contexts/CartContext";
import { Helmet } from "react-helmet";
import { IMAGE_BASE_URL } from "../../utils/api-client";
import cartImg from "../../assets/media/empty-cart.png";

const CartPage = () => {
  const [subTotal, setSubTotal] = useState(0);

  const { cart, removeFromCart, updateCart } = useContext(CartContext);

  useEffect(() => {
    let total = 0;
    cart.forEach((item) => {
      total = total += item.product.varients[0].final_price * item.quantity;
    });
    setSubTotal(total);
  }, [cart]);

  return (
    <>
      <Helmet>
        <title>
          Hurry up! Fill your Shopping Cart with Unique Home Products{" "}
        </title>
        <meta
          name="keywords"
          content="new unique items,creative items for home,creative home decor items,creative decorative items,creative products to sell online,creative memories items for sale,creative gift supplies,creative item for home and office,unique products for home,unique home products,unique office wall decor,interesting home products,unique decorative pieces,unique home essentials,funky home decor items,unique home decorative items,unique home office decor,unique household gadgets"
        />
        <meta
          name="description"
          content="Add your favorite unique home products in a shopping cart and don’t miss the chance of purchasing unique home products to decorate your home at low budget"
        />
      </Helmet>
      <PageHeader title="Shopping Cart" />

      {/* <!-- cart + summary --> */}
      <section class="container-fluid my-5">
        <div class="row">
          {/* <!-- cart --> */}
          <div class="col-lg-9">
            <div class="card border shadow-0">
              <div class="m-4">
                <h4 class="card-title mb-4 ">Your shopping cart</h4>
                {cart.length === 0 ? (
                  <>
                    <div className="text-center">
                      <img
                        className="rounded img-fluid m-1 "
                        style={{ opacity: 0.5, width: 190 }}
                        src={cartImg}
                      />

                      <p className="text-center lead">Your cart is empty.</p>
                      <Link
                        to="/shop/"
                        className="btn btn-info border-0  m-1 text-white"
                        style={{ backgroundColor: "#800080" }}
                      >
                        EXPLORE MORE PRODUCTS
                      </Link>
                    </div>
                  </>
                ) : (
                  cart &&
                  cart.map(({ product, quantity, variant }) => (
                    <div className="row gy-md-3 mb-4 border-bottom rounded">
                      <div className="col-lg-5 col-md-5">
                        <div className="d-flex">
                          {variant.varient_images &&
                            variant.varient_images.length >= 0 && (
                              <img
                                src={`${IMAGE_BASE_URL}/${variant.varient_images[0].image_path}`}
                                className="border rounded me-3 mb-md-3"
                                style={{ width: "96px", height: "96px" }}
                                alt={product.product_name}
                              />
                            )}
                          <div className="">
                            <a href="#" className="h6">
                              {product.product_name}
                            </a>{" "}
                            <p className="">
                              Variant:{" "}
                              <span style={{ color: "#791a89" }}>
                                {variant.p_v_name}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                        <div className="me-4">
                          <QuantityInput
                            quantity={quantity}
                            setQuantity={updateCart}
                            cartPage={true}
                            productId={product.product_id}
                            variantId={variant.p_v_id}
                          />
                        </div>

                        <div className="">
                          <text className="h6">
                            Rs {product.varients[0].final_price * quantity}
                          </text>
                          <br />
                          <small className="text-muted text-nowrap">
                            Rs {product.varients[0].final_price} / per item{" "}
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-6 d-flex justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-end mb-2">
                        <div className="float-md-end">
                          <a
                            // className="btn btn-light border text-danger icon-hover-danger"
                            className="btn btn-danger btn-sm m-1"
                            onClick={() =>
                              removeFromCart(product.product_id, variant.p_v_id)
                            }
                          >
                            Remove
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div class="border-top pt-4 mx-4 mb-4">
                <p>
                  <i class="fas fa-truck text-muted fa-lg"></i> Free Shipping
                  for all Orders above RS 3000
                </p>
              </div>
            </div>
          </div>

          {/* <!-- summary --> */}
          <div class="col-lg-3 price_card ">
            <div class="card shadow-0 border ">
              <div class="card-body sticky m-2 p-4">
                <h3 className="h4 mb-4 text-black ">Cart Total</h3>
                <div class="d-flex justify-content-between">
                  <p class="mb-2">Total price:</p>
                  <p class="mb-2">Rs {subTotal}</p>
                </div>
                {/* <div class="d-flex justify-content-between">
                  <p class="mb-2">Discount:</p>
                  <p class="mb-2 text-success">-$60.00</p>
                </div>
                <div class="d-flex justify-content-between">
                  <p class="mb-2">TAX:</p>
                  <p class="mb-2">$14.00</p>
                </div> */}
                <hr />
                <div class="d-flex justify-content-between">
                  <p class="mb-2">Total price:</p>
                  <p class="mb-2 fw-bold"> Rs {subTotal}</p>
                </div>

                <div class="mt-3">
                  <Link to="/checkout" class="btn btn-success w-100 p-2 mb-2">
                    Proceed to checkout
                  </Link>
                  <Link to="/Shop" class="btn btn-light w-100 border mt-2">
                    Continue shopping
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CartPage;

import React from "react";
import "./Topbar.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Topbar = () => {
  return (
    <div className="header_top d-flex d-none d-lg-block">
      <div className="container-fluid">
        <div className=" row">
          <div className="col-lg-5 col-md-5">
            <div className=" align_center header_top_left ">
              <ul className=" d-flex gap-5 my-2 p-0 ">
                <li>
                  <a
                    href="https://api.whatsapp.com/send?phone=923459403395"
                    className="text-white"
                    target="_blank"
                  >
                    <i className="fa-brands fa-whatsapp"></i> +923459403395
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:support@bakumia.pk"
                    className="text-white"
                    target="_blank"
                  >
                    <i className="fa-solid fa-envelope"></i> support@bakumia.pk
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 mt-2">
            <div className="header__top__right">
              <marquee className="text-white">
                Free Shipping for all Orders above RS 3000
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;

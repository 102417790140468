import React, { useEffect, useState } from "react";

import "./HomePage.css";

import promo from "../../assets/media/promotional_images/promo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faComment,
  faCreditCard,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";

import bannerImg1 from "../../assets/media/promotional_images/1693569308.webp";
import bannerImg2 from "../../assets/media/promotional_images/1693569534.webp";

import Slider from "./Slider";
import CategoriesList from "./CategoriesList";
import ProductCard from "../Shop/ProductCard";
import { NavLink } from "react-router-dom";
import CardSkeleton from "../Common/CardSkeleton";
import apiClient from "../../utils/api-client";
import { Helmet } from "react-helmet";
import YouMayAlsoLikeProducts from "./YouMayAlsoLikeProducts";

const HomePage = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [guaranteedProducts, setGuaranteedProducts] = useState([]);
  const [ladiesBagProducts, setLadiesBagProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchFeaturedProducts = () => {
      apiClient
        .get("/is-featured")
        .then((response) => {
          setFeaturedProducts(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setIsLoading(false);
        });
    };

    const fetchGuaranteedProducts = () => {
      apiClient
        .get("/price/products?product_price=200")
        .then((response) => {
          setGuaranteedProducts(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setIsLoading(false);
        });
    };

    const fetchLadiesBagProducts = () => {
      apiClient
        .get("/subcategory/handbags")
        .then((response) => {
          setLadiesBagProducts(response.data.data.products);
        })
        .catch((error) => {
          setError(error.message);
        });
    };

    fetchFeaturedProducts();
    fetchGuaranteedProducts();
    fetchLadiesBagProducts();
  }, []);

  return (
    <>
      <Helmet>
        <title>Explore the Best Online Shopping Website in Pakistan </title>
        <meta
          name="keywords"
          content="creative items,unique items,creative items to sell,creative craft items,creative gift items,creative home decor products,unique home accessories,best home decor gifts,unique kitchen decor,unique modern home decor,unique decorative items,unique house accessories,cheap home decor gifts,unique kitchen products,unique kitchenware itemsunique kitchen items,home and kitchen unique products,unique kitchen accessories,unique kitchen tools"
        />
        <meta
          name="description"
          content="Discover a curated collection of top-quality products on the best online shopping website in Pakistan. Enjoy a seamless shopping experience with a wide range of categories and secure payment options."
        />
      </Helmet>

      {/* Hero Section */}
      <section className="hero_section">
        <div className="container-fluid">
          <div>
            <div className="row">
              {/* Hero Section Left Side Card */}
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="hero_card">
                  <div className="card_image">
                    <img src={promo} alt="product image img-fluid" />
                  </div>
                  <div className="card_details">
                    <h3 className="card_title">Kitchen Gadgets</h3>
                    <NavLink
                      to="/shop/"
                      className="text-white text-decoration-none"
                    >
                      Shop Now
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{ paddingLeft: 7 }}
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-9 co-md-12 flex-grow-1">
                {/* Hero Section Right Slide Slider */}
                <div className="hero_slider">
                  <Slider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features_section">
        <div className="container-fluid">
          <div className="row align_center">
            <div className="col-12 col-lg-4 col-md-6  d-flex featrue_box p-4">
              <FontAwesomeIcon icon={faTruck} className="feature_icon" />
              <div className="feature_details">
                <h5 className="feature_title">Cash On delivery</h5>
                <p className="feature_text">Terms & Conditions Apply</p>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 d-flex featrue_box border-dark border-start border-end p-4">
              <FontAwesomeIcon icon={faCreditCard} className="feature_icon" />
              <div className="feature_details">
                <h5 className="feature_title">SECURE PAYMENT</h5>
                <p className="feature_text">100% Secure Payment</p>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-12 d-flex featrue_box p-4">
              <FontAwesomeIcon icon={faComment} className="feature_icon" />
              <div className="feature_details">
                <h5 className="feature_title">STRONG CUSTOMER SUPPORT</h5>
                <p className="feature_text">With Quick Response</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Categories Section */}
      <section className="categories_section">
        <div className="container_fluid">
          <div className="col-12">
            <div className="categories_title pb-4">
              <h2>Categories</h2>
              <hr className="mb-2" />
            </div>
            <div className="categories_text">
              <p>
                Explore further and dive into more categories of distinctive and
                creative accessories that you all need. Enjoy wonderful journey
                of online shopping with us by adding every category of product
                in your cart.
              </p>
            </div>
          </div>
          <div className=" ps-1 mx-1 overflow-hidden">
            <CategoriesList />
          </div>
        </div>
      </section>

      {/* Featured Products Section */}
      <section className="featured_product_section">
        <div className="container-fluid">
          <div className="col-12">
            <div className="featured_product_title pb-4">
              <h2>Featured Product</h2>
              <hr className="mb-2" />
            </div>
            <div className="featured_product_text">
              <p>
                Select exquisite products from our wonderful collection of
                amazing products and enhance your shopping experience. Here is a
                bundle of creative items available, so click these items and
                purchase them.
              </p>
            </div>
          </div>
          <div className="row">
            {/* featured prodcuts goes here */}
            {error && <em className="form_error">{error}</em>}
            {isLoading && <CardSkeleton cards={8} />}
            {featuredProducts.map((product) => (
              <div
                key={product.product_id}
                className="col-lg-3 col-md-4 col-sm-6 col-6 p-2"
              >
                <ProductCard key={product.product_id} product={product} />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Ladies Bag Products Section */}
      <section className="ladies_product_section">
        <div className="container-fluid">
          <div className="col-12">
            <div className="ladies_product_title pb-4">
              <h2>Ladies Bags</h2>
              <hr className="mb-2" />
            </div>
            <div className="ladies_product_text">
              <p>
                Style yourself from our beautiful collection of ladies’
                handbags. We have a diversity of handbags in every color and
                style that complete your personality and add a touch of elegance
                to it.
              </p>
            </div>
          </div>
          <div className=" row">
            {/* Ladies bag prodcuts goes here */}

            {error && <em className="form_error">{error}</em>}
            {isLoading && <CardSkeleton cards={8} />}
            {ladiesBagProducts &&
              ladiesBagProducts.slice(0, 8).map((product) => (
                <div
                  key={product.product_id}
                  className="col-lg-3 col-md-4 col-sm-6 col-6 p-2"
                >
                  <ProductCard key={product.product_id} product={product} />
                </div>
              ))}
          </div>
        </div>
      </section>

      {/* Promo Banner Section */}
      <section className="banner_section">
        <div className="container-fluid mt-4">
          <div className="row promo_card">
            <div className="col-lg-6 col-md-6 col-sm-12 position-relative">
              <img
                src={bannerImg1}
                class="d-block w-100 img-fluid max-width: 100%"
                alt="..."
              />
              <div class=" float-end  mb-3">
                <div class="promo_card_left_link ">
                  <NavLink to="/shop/" class="promo_card_btn">
                    Shop Now
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 position-relative">
              <img
                src={bannerImg2}
                class="d-block w-100 img-fluid max-width: 100%"
                alt="..."
              />
              <div class=" float-start  mb-3">
                <div class="promo_card_right_link">
                  <NavLink to="/shop/" class="promo_card_btn">
                    Shop Now
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Price guaranteed Products Section */}
      <section className="price_grnted_product_section">
        <div className="container-fluid">
          <div className="col-12">
            <div className="price_grnted_product_title pb-4">
              <h2>Guaranteed Lowest Prices</h2>
              <hr className="mb-2" />
            </div>
            <div className="price_grnted_product_text">
              <p>Re-do Your House with Unique House Accessories for You</p>
            </div>
          </div>
          <div className=" row">
            {/* Low Price prodcuts goes here */}
            {error && <em className="form_error">{error}</em>}
            {isLoading && <CardSkeleton cards={8} />}
            {guaranteedProducts.map((product) => (
              <div
                key={product.product_id}
                className="col-lg-3 col-md-4 col-sm-6 col-6 p-2"
              >
                <ProductCard key={product.product_id} product={product} />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Random (You mayb Also like) Products Section */}
      <YouMayAlsoLikeProducts />

      {/* Store Details Section */}
      <section className="store_details_section">
        <div className="container-fluid">
          <div className="col-12">
            <div className="store_details_title pb-4">
              <h2>
                Best Online Shopping Website in Pakistan for All Your Needs
              </h2>
              <hr className="mb-2" />
            </div>
            <div className="store_details_text">
              <p>
                In the fast-paced digital age, finding a reliable online
                shopping platform that caters to a diverse range of needs is
                essential. Look no further, as we present the
                <strong> best online shopping website in Pakistan </strong> that
                offers an extensive array of products, from travel accessories
                to household items. Our platform is designed to provide
                convenience, quality, and affordability, ensuring a seamless
                shopping experience for every customer.
              </p>
              <p>
                For those with a penchant for wanderlust, our
                <strong> travel accessories</strong> section is a treasure trove
                of essentials. Whether you're a seasoned globetrotter or
                planning your first adventure, we have curated a selection of
                top-notch travel accessories to accompany you on your journeys.
                From durable luggage sets to compact travel pillows, we've got
                you covered.
              </p>
              <p>
                In the heart of every home, the kitchen is where culinary
                creativity comes to life. Our
                <strong>kitchen accessories</strong>
                range boasts an impressive collection of tools and gadgets that
                will elevate your cooking experience. From versatile cookware to
                innovative utensils, we offer products that cater to both
                seasoned chefs and amateur cooks, ensuring that your kitchen is
                well-equipped for any culinary endeavor.
              </p>
              <p>
                We understand that caring for your little ones is of paramount
                importance. Our <strong>baby care products</strong> are import
                ProductCard from './../Shop/ProductCard'; thoughtfully curated
                to provide the best for your bundle of joy. From soft and
                comfortable clothing to safe and nurturing skincare, our range
                encompasses all aspects of baby care, ensuring that your little
                one receives the love and attention they deserve.
              </p>
              <p>
                The bathroom is a sanctuary of relaxation and rejuvenation. Our
                <strong>bathroom accessories</strong>
                collection combines style with functionality, offering a range
                of products that enhance your personal space. From luxurious
                bath linens to space-saving storage solutions, we provide
                everything you need to create a tranquil oasis within your home.
              </p>
              <p>
                In the age of technology,
                <strong>bathroom accessories</strong> mobile accessories are
                indispensable. Our selection of high-quality mobile accessories
                ensures that your devices are both protected and enhanced. From
                durable phone cases to fast-charging cables, we offer products
                that cater to the diverse needs of mobile users.
              </p>
              <p>
                Beyond the specific categories, our platform also features an
                extensive range of
                <strong>household items</strong>. From cleaning supplies to
                organizational tools, we understand that a well-maintained home
                is a happy home. Our household items section is designed to
                cater to the diverse needs of homeowners, making sure that every
                corner of your living space is taken care of.
              </p>
              <p>
                Experience the convenience and reliability of the
                <strong>best online shopping website in Pakistan</strong> . With
                our curated selection of products, competitive prices, and
                seamless shopping experience, we strive to be your go-to
                destination for all your shopping needs. Shop with us today and
                discover a world of quality and affordability at your
                fingertips.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;

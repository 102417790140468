import React, { useContext, useEffect, useState } from "react";
import "./SingleProductPage.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link, useParams } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import QuantityInput from "./QuantityInput";
import CartContext from "../../contexts/CartContext";
import { Helmet } from "react-helmet";
import { addToWishlist, update } from "../../services/userServices";
import { toast } from "react-toastify";
import UserContext from "../../contexts/UserContext";
import CardSkeleton from "../Common/CardSkeleton";
import ProductCard from "./ProductCard";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReviewStars from "../Common/ReviewStars";

const SingleProductPage = () => {
  const { addToCart } = useContext(CartContext);

  const { product_slug } = useParams();
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);

  const [selectedImage, setSelectedImage] = useState(0);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const [selectedVariant, setSelectedVariant] = useState(0);

  const { user } = useContext(UserContext);

  useEffect(() => {
    apiClient
      .get(`/product/${product_slug}`)
      .then((res) => setProduct(res.data.data))
      .catch((err) => setError(err.message));
  }, [product_slug]);

  useEffect(() => {
    if (product.varients && product.varients.length > 0) {
      setSelectedVariant(0);
    }
  }, [product]);

  const handleVariantClick = (index) => {
    setSelectedVariant(index);
  };

  const generateShortDescription = (description, maxLength) => {
    if (!description || !description.length) {
      return "";
    }
    if (description.length <= maxLength) {
      return description;
    }
    return description.substring(0, maxLength) + "...";
  };

  const shortDescription = generateShortDescription(
    product?.product_details,
    250
  );

  const product_ids = product.product_id;
  const user_id_fk = parseInt(user);

  console.log(relatedProducts);

  const handleAddToWishlist = async () => {
    try {
      await addToWishlist({ user_id_fk, product_ids });
      toast.success("Product added to wishlist successfully!");
    } catch (error) {
      toast.error("Error adding product to wishlist:", error.message);
    }
  };

  useEffect(() => {
    apiClient
      .post("/related-products", {
        product_id: product.product_id,
        category_id_fk: product.category_id_fk,
      })
      .then((res) => {
        setRelatedProducts(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(true);
      });
  }, [product]);

  console.log("product:", product);

  return (
    <>
      <Helmet>
        <title>{product.meta_title}</title>
        <meta name="description" content={product.meta_desc} />
        <meta name="keywords" content={product.keywords} />
      </Helmet>

      <section className="py-5">
        <div className="container-fluid">
          <div className="row">
            {/* Product image column */}

            <aside className="col-lg-5 col-md-6">
              <div className="border rounded-4 mb-3 d-flex justify-content-center product_img">
                <img
                  src={
                    product.varients &&
                    product.varients[selectedVariant] &&
                    product.varients[selectedVariant].varient_images &&
                    product.varients[selectedVariant].varient_images[
                      selectedImage
                    ]
                      ? `${IMAGE_BASE_URL}/${product.varients[selectedVariant].varient_images[selectedImage].image_path}`
                      : ""
                  }
                  alt={product.alt_content}
                  className="single_product_display fit rounded-4"
                />
              </div>
              <div className="justify-content-center single_product_thumbnails sticky-top">
                <div className="single_product_thumbnails">
                  {product.varients &&
                    product.varients[selectedVariant] &&
                    product.varients[selectedVariant].varient_images.map(
                      (image, index) => (
                        <img
                          key={index}
                          src={`${IMAGE_BASE_URL}/${image.image_path}`}
                          alt={image.rimg_alt_content}
                          className={
                            selectedImage === index ? "selected_image" : ""
                          }
                          onClick={() => setSelectedImage(index)}
                        />
                      )
                    )}
                </div>
              </div>
            </aside>

            {/* Product details column */}
            <main className="col-lg-7 col-md-6 product_details_coloumn">
              {error && <em className="form_error">{error}</em>}
              {product.varients && product.varients.length > 0 && (
                <div className="ps-lg-3 pt-2 product_details">
                  <h3 className="single_product_title text-dark text-capitalize">
                    {product.product_name} -{" "}
                    <span className="h5" style={{ color: "#791a89" }}>
                      {product.varients[selectedVariant] &&
                        product.varients[selectedVariant].p_v_name}{" "}
                    </span>
                  </h3>
                  <div className="mb-1">
                    <span className="h5" style={{ color: "#791a89" }}>
                      Rs{" "}
                      {product.varients[selectedImage] &&
                        product.varients[selectedImage].final_price}
                      {product.varients[selectedImage] &&
                        product.varients[selectedImage].final_price <
                          product.varients[selectedImage].product_price && (
                          <span className="ms-2 h6 text-muted text-decoration-line-through">
                            Rs {product.varients[selectedImage].product_price}
                          </span>
                        )}
                    </span>
                    <span className="text-muted"> / Per item</span>
                  </div>

                  <span className="fs-12 product_rating ">
                    <i className="fa fa-solid fa-star ">
                      {" "}
                      {product.avg_rating == null ? 0 : product.avg_rating}
                    </i>{" "}
                    {""}
                    <span className="product_rating_count ">
                      ({product.count_rating})
                    </span>
                  </span>

                  <div
                    style={{ fontWeight: 400 }}
                    className="py-3 text-dark "
                    dangerouslySetInnerHTML={{
                      __html: shortDescription, // Use shortDescription instead of product.product_details
                    }}
                  />

                  <div className="row">
                    <dt className="col-lg-3 col-md-5 col-5">Varient Color:</dt>
                    <dd className="col-7">
                      <span
                        className="h6 text-capitalize"
                        style={{ color: "#791a89" }}
                      >
                        {product.varients[selectedVariant] &&
                          product.varients[selectedVariant].p_v_name}{" "}
                      </span>
                    </dd>

                    <dt className="col-6 pt-1 mb-3">Choose Variants:</dt>

                    <div className="single_product_thumbnails">
                      {product.varients &&
                        product.varients.map((variant, index) => (
                          <div className="variant-thumbnail" key={index}>
                            <div
                              className={`product_varients border rounded-2 mb-3 justify-content-center ${
                                selectedVariant === index
                                  ? "selected-variant"
                                  : ""
                              }`}
                            >
                              <img
                                src={
                                  variant.varient_images &&
                                  variant.varient_images[0]
                                    ? `${IMAGE_BASE_URL}/${variant.varient_images[0].image_path}`
                                    : ""
                                }
                                alt={variant.rimg_alt_content}
                                onClick={() => handleVariantClick(index)}
                              />
                              <h6 className="text-center">
                                {variant.p_v_name}
                              </h6>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <hr />

                  <div className="row mb-3  pt-2">
                    <dt className="col-lg-3 col-md-5 col-5">Availability:</dt>
                    <dd className="col-7 text-success">In Stock</dd>
                  </div>

                  <div className="row mb-4">
                    <QuantityInput
                      quantity={quantity}
                      setQuantity={setQuantity}
                      stock={product.stock}
                    />
                    <div className=" mb-3 add_to_cart">
                      <button
                        href="#"
                        className="btn  shadow-0 add_to_cart_btn"
                        onClick={() =>
                          addToCart(
                            product,
                            quantity,
                            product.varients[selectedVariant]
                          )
                        }
                        disabled={product.in_stock < 1}
                      >
                        <i className="me-1 fa fa-shopping-basket"></i> Add to
                        cart
                      </button>
                      <Link
                        className="btn btn-light border border-secondary icon-hover px-2 "
                        onClick={handleAddToWishlist}
                      >
                        <i className="me-1 fa fa-heart fa-lg text-center "></i>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </main>
          </div>
        </div>
      </section>

      {/* <!-- Product description section --> */}
      <section className="bg-light border-top py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <div className="border rounded-2 px-3 py-2 bg-white">
                <Tabs
                  defaultActiveKey="description"
                  id="fill-tab-example"
                  fill
                  variant="pills"
                  className="mb-3 mb-3 product_description_tabs  text-white"
                >
                  <Tab
                    eventKey="description"
                    title="Description"
                    className="text-white "
                  >
                    <div className="tab-pane fade show active">
                      <div
                        style={{ fontWeight: 400 }}
                        className="pt-3 text-dark "
                        dangerouslySetInnerHTML={{
                          __html: product.product_details,
                        }}
                      />
                    </div>
                  </Tab>
                  <Tab eventKey="Reviews" title="Reviews">
                    {product.ratings && product.ratings.length > 0 ? (
                      product.ratings.map((review) => (
                        <div
                          className="row justify-content-center align-items-center mb-4"
                          key={review.id}
                        >
                          <div className="col-4 col-md-1 ">
                            <img
                              src={`${IMAGE_BASE_URL}/${
                                review.user && review.user.profile_pic
                              }`}
                              className="w-100 "
                              alt="User profile"
                            />
                          </div>
                          <div className="col-8 col-md-11">
                            <div className="lead bold">
                              {review.user && review.user.full_name}
                            </div>
                            <div>
                              <ReviewStars stars={review && review.rating} />
                            </div>
                            <div className="text-dark fw-normal">
                              {review.user && review.reviews}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>No Product Reviews are available</div>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>

            {/* Related Products Section */}
            <section className="bg-light mt-4">
              <div className="container-fluid">
                <div className="">
                  <div className="col-12">
                    <div className="featured_product_title pb-4">
                      <h2>Related Products</h2>
                      <hr className="mb-2" />
                    </div>
                    <div className="featured_product_text">
                      <p>
                        Explore further and get more from our wide collection of
                        unique and creative items.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    {/* featured prodcuts goes here */}
                    {error && <em className="form_error">{error}</em>}
                    {isLoading && <CardSkeleton cards={8} />}
                    {relatedProducts &&
                      relatedProducts.map((product) => (
                        <div
                          key={product.product_id}
                          className="col-lg-3 col-md-4 col-sm-6 col-6 p-2"
                        >
                          <ProductCard
                            key={product.product_id}
                            product={product}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleProductPage;

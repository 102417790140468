import React, { useState, useEffect } from "react";
import "./OffcanvasMenu.css";
import logo from "../../assets/brand/logo1.png";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import "@fortawesome/fontawesome-free/css/all.min.css";

const OffcanvasMenu = ({ user, setUser, cartCount }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNavLinkClick = () => {
    setShow(false);
  };

  const options = [
    {
      name: "Enable backdrop (default)",
      scroll: false,
      backdrop: true,
    },
  ];

  return (
    <>
      <Button
        className="navbar-toggler d-lg-none"
        type="button"
        variant="warning"
        onClick={handleShow}
      >
        <span className="navbar-toggler-icon"></span>
      </Button>
      <Offcanvas
        show={show}
        closeButton
        onHide={handleClose}
        className="humberger_menu_wrapper show_humberger_menu_wrapper p-0 m-0 w-75"
      >
        <div className="humberger_menu_logo mb-3">
          <NavLink to="/" onClick={handleNavLinkClick}>
            <img src={logo} alt="" />
          </NavLink>
        </div>
        <div className="humberger_menu_cart">
          <ul className="list-group list-group-horizontal ">
            <li className="list-group-item  border-none">
              <NavLink to="/wishlist/" onClick={handleNavLinkClick}>
                <i className="fa fa-heart"></i>{" "}
                {/* <sup className="wishlist"> </sup> */}
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink to="/cart/" onClick={handleNavLinkClick}>
                <i id="totalitem" className="fa fa-shopping-bag">
                  <sup id="total" className="ms-1">
                    {cartCount}
                  </sup>
                </i>
              </NavLink>
            </li>
          </ul>
        </div>
        <nav className="humberger_menu_nav mobile-menu">
          <ul className="list-group list-group-flush bg-transparent">
            <li className="list-group-item">
              <NavLink to="/" onClick={handleNavLinkClick}>
                Home
              </NavLink>
            </li>
            <li className="list-group-item ">
              <NavLink to="/shop/" onClick={handleNavLinkClick}>
                Shop
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink to="/blogs/" onClick={handleNavLinkClick}>
                Blog
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink to="/contact/" onClick={handleNavLinkClick}>
                Contact
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink to="/videos/" onClick={handleNavLinkClick}>
                Videos
              </NavLink>
            </li>

            {!(user !== null && user !== "") && (
              <li className="list-group-item">
                <NavLink to="/login/" onClick={handleNavLinkClick}>
                  Login
                </NavLink>
              </li>
            )}

            {user !== null && user !== "" && (
              <>
                <li className="list-group-item" onClick={handleNavLinkClick}>
                  <NavLink to="/account/profile/">My Account</NavLink>
                </li>
                <li className="list-group-item" onClick={handleNavLinkClick}>
                  <NavLink to="/account/logout/">Logout</NavLink>
                </li>
              </>
            )}
          </ul>
        </nav>
        <div id="mobile-menu-wrap">
          <div className="slicknav_menu">
            <a
              href="#"
              aria-haspopup="true"
              tabIndex="0"
              className="slicknav_btn slicknav_collapsed"
              style={{ outline: "none" }}
              onClick={handleNavLinkClick}
            >
              <span className="slicknav_icon">
                <span className="slicknav_icon-bar"></span>
                <span className="slicknav_icon-bar"></span>
                <span className="slicknav_icon-bar"></span>
              </span>
            </a>
          </div>
        </div>
        <div className="header_top_right_social pt-2 mb-3">
          <a
            href="https://www.facebook.com/bakumia.pk"
            target="_blank"
            onClick={handleNavLinkClick}
          >
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/bakumia.pk/"
            target="_blank"
            onClick={handleNavLinkClick}
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://twitter.com/BakumiaP"
            target="_blank"
            onClick={handleNavLinkClick}
          >
            <i className="fa-brands fa-twitter"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UClgC42QRT6uQFLofuy1We4g"
            target="_blank"
            onClick={handleNavLinkClick}
          >
            <i className="fa-brands fa-youtube"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/bakumia/"
            target="_blank"
            onClick={handleNavLinkClick}
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>
        </div>
        <div className="humberger_menu_contact">
          <ul className="list-group-item">
            <li className="list-group-item">
              <i className="fa-brands fa-whatsapp"></i>
              <a href="tel:03459403395" onClick={handleNavLinkClick}>
                {" "}
                +923459403395
              </a>
            </li>
            <li className="list-group-item">
              <i className="fa-regular fa-envelope"></i>
              <a href="mailto:support@bakumia.com" onClick={handleNavLinkClick}>
                {" "}
                support@bakumia.com
              </a>
            </li>
            <li className="list-group-item">
              Free Shipping for all Orders above RS 3000
            </li>
          </ul>
        </div>
      </Offcanvas>
    </>
  );
};

export default OffcanvasMenu;

import React, { useState, useEffect } from "react";
import "./CategoriesList.css";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { NavLink } from "react-router-dom";
import CategoriesListSkeleton from "../Common/CategoriesListSkeleton";

const CategoriesList = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiClient
      .get("/category")
      .then((res) => {
        setCategories(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="row justify-content-center">
      {error && <em className="form_error">{error}</em>}
      {isLoading && <CategoriesListSkeleton cards={8} />}
      {categories.map((category, index) => (
        <div
          className="col-lg-1 col-md-2 col-4 px-2 py-1 pb-2 categories_list"
          key={index}
        >
          <NavLink
            to={`/${category.slug}/`}
            className="d-flex flex-column justify-content-center flex-direction-coloumn"
          >
            <img
              src={`${IMAGE_BASE_URL}/${category.pcat_image}`}
              alt="category Image"
              className="image-fluid mx-auto"
            />
            <h5>{category.p_category_name} </h5>
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default CategoriesList;

import React, { useContext } from "react";
import UserContext from "./contexts/UserContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const userData = JSON.parse(localStorage.getItem("userID"));

  const location = useLocation();

  if (!userData) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import PageHeader from "./../Common/PageHeader";
import Skeleton from "react-loading-skeleton";
import { FaStar } from "react-icons/fa";
import UserContext from "../../contexts/UserContext";
import { toast } from "react-toastify";

const OrderDetails = () => {
  const { orderId } = useParams();
  const [orderDetail, setOrderDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [error, setError] = useState("");
  const { user } = useContext(UserContext);

  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [ratingDescription, setRatingDescription] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);

  const userId = localStorage.getItem("userID");

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  const fetchOrderDetails = () => {
    const fetchOrderDetail = async () => {
      try {
        const response = await apiClient.get(`/order-details/${orderId}`);
        setOrderDetail(response.data.data);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchOrderDetail();
  };

  useEffect(() => {
    apiClient
      .get(`/user/${user}`)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [orderId]);

  const addReviewHandler = (e) => {
    e.preventDefault();

    let review = {
      p_id_fk: selectedProductId,
      user_id_fk: userId,
      rating: rating,
      reviews: ratingDescription,
    };

    apiClient
      .post("/add-review", review)
      .then((response) => {
        toast.success(response.data.message);
        fetchOrderDetails();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  console.log("orderDetails", orderDetail);

  return (
    <>
      <PageHeader title="My Order" />
      <div className="p-5">
        {error && <div>Error: {error}</div>}
        <div className="mb-5">
          <h2 className="text-center">Order Detail</h2>
          <hr className="hr" />
          <p className="text-center mt-3 px-5">
            Have your purchased product items details summed up with taxes and
            delivery charges with an error-free total. Bakumia lets you enjoy
            online shopping without any difficulty.
          </p>
        </div>

        <div className="table-responsive">
          <table className="table text-center table-striped">
            <thead>
              <tr className="m-3">
                <th>Product Image</th>
                <th>Product Name</th>
                <th>Color</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total Cost</th>
                {orderDetail.status_id_fk === 3 && <th>Rating</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan="7">
                    <Skeleton count={3} />
                  </td>
                </tr>
              )}
              {orderDetail &&
                orderDetail.order_detail &&
                orderDetail.order_detail.length > 0 &&
                orderDetail.order_detail.map((orderItem, index) => (
                  <tr className="align-middle" key={index}>
                    <td>
                      <img
                        src={`${IMAGE_BASE_URL}/${orderItem.varients[0].product.product_image}`}
                        alt={orderItem.varients[0].product.product_name}
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </td>
                    <td>{orderItem.varients[0].product.product_name}</td>
                    <td>{orderItem.varients[0].p_v_name}</td>
                    <td>{orderItem.varients[0].final_price}</td>
                    <td>{orderItem.quantity}</td>
                    <td>{orderItem.total_cost * orderItem.quantity}</td>
                    {orderDetail.status_id_fk === 3 && (
                      <td>
                        <div>
                          <button
                            className="btn btn-danger border-0 mt-2 ms-3 text-white"
                            type="button"
                            style={{ backgroundColor: "#800080" }}
                            data-bs-toggle="modal"
                            data-bs-target={`#reviewModal-${index}`} // Unique ID for each modal
                            onClick={() =>
                              setSelectedProductId(
                                orderItem.varients[0].product &&
                                  orderItem.varients[0].product.product_id
                              )
                            }
                          >
                            Write A Review
                          </button>

                          <div
                            className="modal fade"
                            id={`reviewModal-${index}`} // Unique ID for each modal
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="reviewModalTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                              role="document"
                            >
                              <div className="modal-content">
                                <form
                                  onSubmit={(e) =>
                                    addReviewHandler(
                                      e,
                                      orderItem.varients[0].product.id
                                    )
                                  }
                                >
                                  <div
                                    className="modal-header text-white"
                                    style={{ backgroundColor: "#800080" }}
                                  >
                                    <h1
                                      className="modal-title fs-5"
                                      id="shippingModalLabel"
                                    >
                                      How was the product?
                                    </h1>
                                    <button
                                      type="button"
                                      className="btn-close text-white"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    />
                                  </div>

                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-2 px-0 mx-3">
                                        <img
                                          src={`${IMAGE_BASE_URL}/${userData.profile_pic}`}
                                          style={{
                                            maxWidth: "90px",
                                            maxHeight: "90px",
                                          }}
                                        />
                                      </div>
                                      <div className="col-9 text-start">
                                        <textarea
                                          rows={2}
                                          className="form-control"
                                          name="reviews"
                                          id="reviews"
                                          value={ratingDescription}
                                          onChange={(e) =>
                                            setRatingDescription(e.target.value)
                                          }
                                          required
                                        />

                                        {[...Array(5)].map((_, index) => {
                                          const currentRating = index + 1;
                                          return (
                                            <label key={currentRating}>
                                              <input
                                                type="radio"
                                                name="rating"
                                                className="d-none"
                                                value={currentRating}
                                                onClick={() =>
                                                  setRating(currentRating)
                                                }
                                              />
                                              <FaStar
                                                style={{ cursor: "pointer" }}
                                                size={40}
                                                className="pe-2"
                                                color={
                                                  currentRating <=
                                                  (hover || rating)
                                                    ? "#ffc107"
                                                    : "#e4e5e9"
                                                }
                                                onMouseEnter={() =>
                                                  setHover(currentRating)
                                                }
                                                onMouseLeave={() =>
                                                  setHover(null)
                                                }
                                              />
                                            </label>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="submit"
                                      className="btn btn-primary border-0"
                                      style={{ backgroundColor: "#800080" }}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;

import React from "react";
import "./ContactPage.css";
import PageHeader from "./../Common/PageHeader";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { contact } from "../../services/userServices";

const ContactPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    contact(formData)
      .then((response) => {
        if (response && response.data.status === false) {
          const errorMessages = response.data.data.map((error) => {
            return error;
          });
          toast.error(errorMessages.join(", "));
        } else if (response && response.data.status === true) {
          toast.success("Contact Message Send Succesfully");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while sending message.");
      });
  };

  return (
    <>
      <Helmet>
        <title>Our Stellar Services Are Available All the Time </title>
        <meta
          name="keywords"
          content="creative items,unique items,creative items to sell,creative craft items,creative gift items,creative home decor products,unique home accessories,best home decor gifts,unique kitchen decor,unique modern home decor,unique decorative items,unique house accessories,cheap home decor gifts,unique kitchen products,unique kitchenware itemsunique kitchen items,home and kitchen unique products,unique kitchen accessories,unique kitchen tools"
        />
        <meta
          name="description"
          content="Contact us through the given details if you have any complaints about our unique home products. Our team is available to assist you with any query you might have."
        />
      </Helmet>
      <PageHeader title="Contact Us" />
      <div className="container">
        <div className="row align-items-center pt-5 mt-2">
          <div className="dark-grey-text col-lg-6 ">
            {/* <!-- Section heading --> */}
            <h3 className="font-weight-bold mb-4 pb-2 ">Send us a message</h3>
            <hr className="w-header " />
            {/* <!-- Section description --> */}
            <p className="lead w-responsive mx-auto text-muted mt-4 pt-2 mb-5 pe-5">
              Get in touch with us today or visit our website to choose
              <strong> unique decorative items </strong> for yourself.
            </p>

            <div className="row">
              <div className="col-md-5 mb-4">
                <a
                  href="tel:03459403395"
                  className="card hoverable"
                  target="_blank"
                >
                  <div className="card-body">
                    <div className="media">
                      <span className="card-img-100 d-inline-flex justify-content-center align-items-center rounded-circle grey lighten-3 mr-4">
                        <i className="fa-solid fa-phone"></i>
                      </span>
                      <div className="media-body">
                        <h5 className="dark-grey-text mb-3">Phone</h5>
                        <p className="font-weight-light text-muted mb-0">
                          03459403395
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-md-5 mb-4">
                <a
                  href="https://wa.me/+923459403395"
                  className="card hoverable"
                  target="_blank"
                >
                  <div className="card-body">
                    <div className="media">
                      <span className="card-img-100 d-inline-flex justify-content-center align-items-center rounded-circle grey lighten-3 mr-4">
                        <i className="fa-brands fa-square-whatsapp"></i>
                      </span>
                      <div className="media-body">
                        <h5 className="dark-grey-text mb-3">Whatsapp</h5>
                        <p className="font-weight-light text-muted mb-0">
                          03459403395
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5 mb-4">
                <a
                  href="mailto:support@bakumia.pk"
                  className="card hoverable"
                  target="_blank"
                >
                  <div className="card-body">
                    <div className="media">
                      <span className="card-img-100 d-inline-flex justify-content-center align-items-center rounded-circle grey lighten-3 mr-4">
                        <i className="fa-solid fa-envelope"></i>
                      </span>
                      <div className="media-body">
                        <h5 className="dark-grey-text mb-3">Email</h5>
                        <p className="font-weight-light text-muted mb-0">
                          support@bakumia.pk
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-md-5 mb-4">
                <a className="card hoverable">
                  <div className="card-body">
                    <div className="media">
                      <span className="card-img-100 d-inline-flex justify-content-center align-items-center rounded-circle grey lighten-3 mr-4">
                        <i className="fa-solid fa-clock"></i>
                      </span>
                      <div className="media-body">
                        <h5 className="dark-grey-text mb-3">Working Hours</h5>
                        <p className="font-weight-light text-muted mb-0">
                          Avalaible 24/7 Hours
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="box">
              <h3 className="heading">SEND US A MESSAGE</h3>
              <form
                className="mb-5"
                id="contactForm"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="col-md-12 form-group">
                    <label htmlFor="name" className="col-form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      {...register("name", { required: true })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 form-group">
                    <label htmlFor="email" className="col-form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="email"
                      {...register("email", { required: true })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 form-group">
                    <label htmlFor="phone" className="col-form-label">
                      Phone
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="phone"
                      id="phone"
                      {...register("phone", { required: true })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 form-group">
                    <label htmlFor="subject" className="col-form-label">
                      Suject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      {...register("subject", { required: true })}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12 form-group">
                    <label htmlFor="message" className="col-form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      name="message"
                      id="message"
                      cols="30"
                      rows="7"
                      {...register("message", { required: true })}
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="submit"
                      value="Send Message"
                      className="btn btn-block rounded-0 py-2 px-4 submit_button"
                    />
                    <span className="submitting"></span>
                  </div>
                </div>
              </form>

              <div id="form-message-warning mt-4"></div>
              <div id="form-message-success">
                Your message was sent, thank you!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;

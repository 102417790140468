import React from "react";
import "./Footer.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import appStoreImg from "../../assets/media/appStore.png";
import googlePlayImg from "../../assets/media/googlePlay.png";
import logo from "../../assets/brand/logo1.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="footer_widget">
              <div className="footer_about_logo">
                <Link to="/">
                  <img src={logo} alt="" className="img-fluid" />
                </Link>
              </div>
              <p>Household and kitchenware unique products</p>
              <div className="footer_widget_social">
                <a
                  href="https://api.whatsapp.com/send?phone=923459403395"
                  target="_blank"
                >
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
                <a href="https://www.facebook.com/bakumia.pk" target="_blank">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/bakumia.pk/" target="_blank">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="https://twitter.com/BakumiaP" target="_blank">
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UClgC42QRT6uQFLofuy1We4g"
                  target="_blank"
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/bakumia/"
                  target="_blank"
                >
                  <i className="fa-brands fa-linkedin"></i>
                </a>
                <a href="https://www.pinterest.com/bakumia22/" target="_blank">
                  <i className="fa-brands fa-pinterest"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="footer_widget">
              <div className="row">
                <div className="col-md-5 col-sm-6">
                  <h6>Web Links</h6>
                  <ul>
                    <li>
                      <Link to="/shop/">Shop</Link>
                    </li>
                    <li>
                      <Link to="/blogs/">Blog</Link>
                    </li>
                    <li>
                      <Link to="/contact/">Contact</Link>
                    </li>
                    <li>
                      <Link to="/sitemap/">Sitemap</Link>
                    </li>

                    <li>
                      <Link to="/about/">About Us</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-7 col-sm-6">
                  <h6>Useful Links</h6>
                  <ul>
                    <li>
                      <Link to="/faqs/">FAQs</Link>
                    </li>
                    <li>
                      <Link to="return-policy/">Return Policy</Link>
                    </li>
                    <li>
                      <Link to="privacy-policy/">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="terms-and-conditions/">
                        Terms &amp; Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="footer_about">
              <h6 className="fw-700">Contact</h6>
              <ul>
                <li>Address: FF1041, Deans Trade Center, Peshawar</li>
                <li className="mb-2">
                  Email:
                  <a href="mailto:support@bakumia.pk" target="_blank">
                    {" "}
                    support@bakumia.pk
                  </a>
                </li>
                <li className="mb-2">
                  <div className="row ">
                    <div className="col-md-1 col-2">
                      <i
                        className="fa-brands fa-square-whatsapp"
                        style={{ fontSize: 50, color: "#008000" }}
                      ></i>
                    </div>
                    <div className="col-8 px-4  footer_contact_info ">
                      <h6 className="fs-14 m-0 ">Have any questions?</h6>
                      <a
                        href="https://api.whatsapp.com/send?phone=923459403395"
                        target="_blank"
                      >
                        03459403395
                      </a>
                    </div>
                  </div>
                </li>
                <li className="download_button">
                  <img src={appStoreImg} width="30%" />
                  <a
                    href="https://play.google.com/store/apps/details?id=com.bakumia"
                    target="_blank"
                  >
                    <img src={googlePlayImg} width="30%" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0">
        <div className="footer_copyright">
          <div className="row">
            <div className="col-lg-8">
              <div className="footer_copyright_text">
                <p>
                  Copyright <span id="copyrightYear">2024</span> All rights
                  reserved | Bakumia Powered by{" "}
                  <a href="https://www.devifysolutions.com/" target="_blank">
                    Devify Solutions
                  </a>{" "}
                  <i className="fa fa-heart text-danger" aria-hidden="true"></i>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row">
                <div className="col-4">
                  <span className="text-info">Easypaisa</span>
                </div>
                <div className="col-4">
                  <span className="text-danger">Jazzcash</span>
                </div>
                <div className="col-4">
                  <span className="text-primary">Bank Alfalah</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

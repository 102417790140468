import React from "react";
import Skeleton from "react-loading-skeleton";

const CategoriesListSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((index) => (
      <section
        key={`category_${index}`}
        className="col-lg-1 col-md-2 col-4 px-2 py-1 pb-2 categories_list"
      >
        <Skeleton circle height={100} />

        <h5 className="w-100 product_title">
          <Skeleton count={1} />
        </h5>
      </section>
    ));
};

export default CategoriesListSkeleton;

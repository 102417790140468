import React from "react";
import PageHeader from "../Common/PageHeader";
import { Helmet } from "react-helmet";
import MyWishlist from "./../UserAccount/MyWishlist";

const WishlistPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Save your Preferred Unique Home Products in your Wishlist{" "}
        </title>
        <meta
          name="keywords"
          content="new unique items,creative items for home,creative home decor items,creative decorative items,creative products to sell online,creative memories items for sale,creative gift supplies,creative item for home and office,unique products for home,unique home products,unique office wall decor,interesting home products,unique decorative pieces,unique home essentials,funky home decor items,unique home decorative items,unique home office decor,unique household gadgets"
        />
        <meta
          name="description"
          content="Save your chosen Unique Home Products in your Wishlist to Purchase or see whenever you want. So that you can avail the chance of purchasing specific item on time"
        />
      </Helmet>
      <PageHeader title="Bakumia Wishlist" />

      {/* <!-- cart + summary --> */}
      <section className="container-fluid my-5">
        <div className="row">
          {/* <!-- cart --> */}
          <div className="col-lg-12">
            <div className="mb-5">
              <h2 className="text-center">Wishlist</h2>
              <hr className="hr" />
              <p className="text-center mt-3 px-5">
                If your favorite unique home product has sold out, don’t worry
                you can add product in your Wishlist. So that you can buy it
                after restocking or can see product easily whenever you want
                from there.
              </p>
            </div>
            <MyWishlist />
          </div>
        </div>
      </section>
    </>
  );
};

export default WishlistPage;

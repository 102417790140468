import React, { useEffect, useState } from "react";
import PageHeader from "./Common/PageHeader";
import { useLocation } from "react-router-dom";
import SearchProductList from "./SearchProductList";
import { Helmet } from "react-helmet";

const SearchResults = () => {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (location.state && location.state.searchResults) {
      setSearchResults(location.state.searchResults);
      setIsLoading(false);
    } else {
      // Handle the case when search data is not available
      setError("No search results found.");
      setIsLoading(false);
    }
  }, [location.state]);

  return (
    <>
      <Helmet>
        <title>
          Online Shopping Stores - Online Shopping in Lahore - Karachi
        </title>
        <meta
          name="keywords"
          content="creative items,unique items,creative items to sell,creative craft items,creative gift items,creative home decor products,unique home accessories,best home decor gifts,unique kitchen decor,unique modern home decor,unique decorative items,unique house accessories,cheap home decor gifts,unique kitchen products,unique kitchenware itemsunique kitchen items,home and kitchen unique products,unique kitchen accessories,unique kitchen tools"
        />
        <meta
          name="description"
          content="Planning for online jewellery & gift shopping? Try Bakumia & get your desired products in online in Islamabad, Multan, Faisalabad & all across the Pakistan."
        />
      </Helmet>
      <PageHeader title="Search Results" />
      <section className="append_products">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {isLoading ? (
                <p>Loading...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <SearchProductList searchData={searchResults} />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchResults;

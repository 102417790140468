import React from "react";
import "./UserAccount.css";
import PageHeader from "./../Common/PageHeader";
import { NavLink, Outlet } from "react-router-dom";

const UserAccount = () => {
  return (
    <>
      <PageHeader title="My Profile" />
      <section className="container-fluid mt-5">
        <div className="row justify-content-between">
          <div className="col-md-3 col-12">
            <div className="border rounded profile_sidebar">
              <ul className="ul">
                <li className="li">
                  <NavLink to="/account/profile/">Account Main</NavLink>
                </li>
                <li className="li">
                  <NavLink to="/account/orders-history/">
                    Orders History
                  </NavLink>
                </li>
                <li className="li">
                  <NavLink to="/account/my-wishlist/">My Wishlist</NavLink>
                </li>
                <li className="li">
                  <NavLink to="/account/profile-setting/">
                    Profile Setting
                  </NavLink>
                </li>
                <li className="li">
                  <NavLink to="/account/logout/">Logout</NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-9 col-12 ">
            <div className="border rounded w-100 h-100 profile_details ">
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserAccount;

import React, { useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../../utils/api-client";

const ForgotPassword = () => {
  const [email, setEmail] = useState(""); // State to hold email value

  const handleResetPassword = () => {
    // Call forgot password API
    apiClient
      .post("/forgot-password", { email })
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success("Successful! Please Check your email inbox");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while resetting password.");
      });
  };

  return (
    <>
      <a
        className="float-end"
        data-bs-toggle="modal"
        data-bs-target="#forgotPasswordModal"
      >
        Forgot Password?
      </a>
      {/* Modal */}
      <div
        className="modal fade"
        id="forgotPasswordModal"
        tabIndex={-1}
        aria-labelledby="forgotPasswordModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div
              className="modal-header text-white"
              style={{ backgroundColor: "#800080" }}
            >
              <h1 className="modal-title fs-5" id="forgotPasswordModalLabel">
                Reset Your Password
              </h1>
            </div>
            <div className="modal-body">
              <div className="row g-3 mb-3">
                <div className="col-12">
                  <label htmlFor="receiver_name" className="mb-2 text-muted">
                    Enter Your Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example@gmail.com"
                    value={email} // Bind input value to state
                    onChange={(e) => setEmail(e.target.value)} // Update email state on change
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary border-0"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary border-0"
                style={{ backgroundColor: "#800080" }}
                onClick={handleResetPassword} // Call handleResetPassword function on button click
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React from "react";
import PageHeader from "./Common/PageHeader";
import { Helmet } from "react-helmet";

const Faqs = () => {
  return (
    <>
      <Helmet>
        <title>
          Questions and their answers customers have while shopping{" "}
        </title>
        <meta
          name="keywords"
          content="creative items,unique items,creative items to sell,creative craft items,creative gift items,creative home decor products,unique home accessories,best home decor gifts,unique kitchen decor,unique modern home decor,unique decorative items,unique house accessories,cheap home decor gifts,unique kitchen products,unique kitchenware itemsunique kitchen items,home and kitchen unique products,unique kitchen accessories,unique kitchen tools"
        />
        <meta
          name="description"
          content="Get an answer to all your questions. Our customer service team will be happy to assist you if you have any further questions. Happy shopping with Bakumia!"
        />
      </Helmet>
      <PageHeader title="FAQs" />
      <section className="">
        <div className="container-fluid p-5">
          <div className="row ">
            <div className="col-md-12 mt-5 ">
              <h2 className="display-6 text-center fw-medium">FAQs</h2>
              <hr className="hr mb-4" />
            </div>

            <div className="col-md-12 mt-2 ">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item my-4  border border-1 rounded-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button py-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Do you need an account for placing an order?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                  >
                    <div className="accordion-body py-5">
                      You can order without registering and complete your order
                      as a guest. However, creating an account will help you in
                      future transactions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item my-4  border border-1 rounded-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button py-4 collapsed rounded-top"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      I have forgotten my password
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body py-5">
                      You can simply click on forgot my password button if you
                      forget the password. An email will be sent to you with the
                      reversal link to reset your password for your account.
                    </div>
                  </div>
                </div>
                <div className="accordion-item my-4  border border-1 rounded-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button py-4 collapsed rounded-top"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Orders
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body py-5">
                      <p className="link">How can I place an Order? </p> Add
                      your products to the bag and proceed to checkout to make
                      the payment. <br />
                      Use your payment method and complete the order. <br />A
                      confirmation email will be sent to confirm your order.
                    </div>
                  </div>
                </div>
                <div className="accordion-item my-4  border border-1 rounded-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button py-4 collapsed rounded-top"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseFour"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseFour"
                    >
                      How to look for an item on the website?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseFour"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body py-5">
                      You can use the search box to search for an item on the
                      website.
                    </div>
                  </div>
                </div>
                <div className="accordion-item my-4  border border-1 rounded-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button py-4 collapsed rounded-top"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseFive"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseFive"
                    >
                      Can I reserve my Order online?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseFive"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body py-5">
                      Unfortunately, you cannot reserve your Order online.
                    </div>
                  </div>
                </div>
                <div className="accordion-item my-4  border border-1 rounded-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button py-4 collapsed rounded-top"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseSix"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseSix"
                    >
                      Shipping & Handling
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseSix"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body py-5">
                      <p className="link">
                        What is the delivery time for my Order?{" "}
                      </p>{" "}
                      Your Order should be delivered within 5 to 7 working days.
                      <p className="link">
                        What are the delivery charges for local Orders?
                      </p>{" "}
                      Delivery charges vary according to your city.
                      <p className="link">
                        What to do if you miss the delivery?
                      </p>{" "}
                      The courier company will contact you to make the delivery.
                      In case you miss it, you can opt for a second attempt to
                      contact us to fulfill the order.
                    </div>
                  </div>
                </div>
                <div className="accordion-item my-4  border border-1 rounded-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button py-4 collapsed rounded-top"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseSeven"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseSeven"
                    >
                      Returns & Exchange
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseSeven"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body py-5">
                      <p className="link">How to Return Product?</p> Bakumia
                      does not have a refund policy. We only offer an exchange
                      on products. However, you may be eligible for refunds in
                      exceptional cases. For example, if an item goes out of
                      stock, the amount will be refunded to you.
                      <p className="link">Canceling Your Order</p> You have the
                      chance to cancel your order before it processes. However,
                      if the order is shipped, you receive a tracking ID to
                      track your order. During this time you cannot cancel the
                      order. After the order is received, you have the option to
                      “return or exchange” your product and the relevant policy
                      will be applied. <br /> Bakumia has the right to cancel
                      your order if it goes out of stock, has a pricing error,
                      or your credit/debit card payment gets declined by your
                      bank.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;

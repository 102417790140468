import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import "./ProductVariantModal.css";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import QuantityInput from "./QuantityInput";
import CartContext from "../../contexts/CartContext";
import { IMAGE_BASE_URL } from "../../utils/api-client";

const ProductVariantModal = ({
  id,
  slug,
  varients,
  stock,
  onClose,
  onVariantSelect,
  subcat_slug,
  product,
}) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const { addToCart } = useContext(CartContext);

  // Function to handle variant selection
  const handleVariantSelect = (variant) => {
    setSelectedVariant(variant);
  };

  useEffect(() => {
    if (product.varients && product.varients.length > 0) {
      setSelectedVariant(0);
    }
  }, [product]);

  const handleVariantClick = (index) => {
    setSelectedVariant(index);
  };

  return (
    <>
      <div className="modal-overlay" onClick={onClose}></div>{" "}
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Variant</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
              ></button>
            </div>

            <section className=" modal-body py-3 overflow-hidden;">
              <div className="container-fluid">
                <div className="row">
                  {/* Product image column */}
                  <aside className="col-lg-5 col-md-6">
                    <div className="border rounded-4 mb-3 d-flex justify-content-center product_img">
                      <img
                        src={
                          product.varients &&
                          product.varients[selectedVariant] &&
                          product.varients[selectedVariant].varient_images &&
                          product.varients[selectedVariant].varient_images[
                            selectedImage
                          ]
                            ? `${IMAGE_BASE_URL}/${product.varients[selectedVariant].varient_images[selectedImage].image_path}`
                            : ""
                        }
                        alt={product.product_name}
                        className="single_product_display fit rounded-4"
                      />
                    </div>
                    <div className="justify-content-center single_product_thumbnails sticky-top">
                      <div className="single_product_thumbnails mb-1">
                        {product &&
                          product.varients &&
                          product.varients[selectedVariant] &&
                          product.varients[selectedVariant].varient_images.map(
                            (image, index) => (
                              <img
                                key={index}
                                src={`${IMAGE_BASE_URL}/${image.image_path}`}
                                alt={image.rimg_alt_content}
                                className={
                                  selectedImage === index
                                    ? "selected_image"
                                    : ""
                                }
                                onClick={() => setSelectedImage(index)}
                              />
                            )
                          )}
                      </div>
                    </div>{" "}
                  </aside>

                  {/* Product details column */}
                  <main className="col-lg-7 col-md-6 product_details_coloumn">
                    {product.varients && product.varients.length > 0 && (
                      <div className="ps-lg-3 pt-2 product_details">
                        <h3 className="single_product_title text-dark text-capitalize">
                          {product.product_name} -{" "}
                          <span className="h5" style={{ color: "#791a89" }}>
                            {product.varients[selectedVariant] &&
                              product.varients[selectedVariant].p_v_name}{" "}
                          </span>
                        </h3>

                        <span className="fs-12 product_rating ">
                          <i className="fa fa-solid fa-star pb-3">
                            {product.avg_rating == null && 0}
                          </i>{" "}
                          {""}
                          <span className="product_rating_count pb-3">
                            ({product.count_rating})
                          </span>
                        </span>

                        <div className="mb-3">
                          <span className="h5" style={{ color: "#791a89" }}>
                            Rs{" "}
                            {product.varients[selectedImage] &&
                              product.varients[selectedImage].final_price}
                            {product.varients[selectedImage] &&
                              product.varients[selectedImage].final_price <
                                product.varients[selectedImage]
                                  .product_price && (
                                <span className="ms-2 h6 text-muted text-decoration-line-through">
                                  Rs{" "}
                                  {
                                    product.varients[selectedImage]
                                      .product_price
                                  }
                                </span>
                              )}
                          </span>
                          <span className="text-muted"> / Per item</span>
                        </div>

                        <p>{product.description}</p>

                        <div className="row">
                          <dt className="col-lg-3 col-md-5 col-5">
                            Variant Color:
                          </dt>
                          <dd className="col-7">
                            <span
                              className="h6 text-capitalize"
                              style={{ color: "#791a89" }}
                            >
                              {product.varients[selectedVariant] &&
                                product.varients[selectedVariant].p_v_name}{" "}
                            </span>
                          </dd>

                          <dt className="col-6 pt-1 mb-3">Choose Variants:</dt>

                          <div className="single_product_thumbnails">
                            {product.varients &&
                              product.varients.map((variant, index) => (
                                <div className="variant-thumbnail" key={index}>
                                  <div
                                    className={`product_varients border rounded-2 mb-3 justify-content-center ${
                                      selectedVariant === index
                                        ? "selected-variant"
                                        : ""
                                    }`}
                                  >
                                    <img
                                      src={
                                        variant.varient_images &&
                                        variant.varient_images[0]
                                          ? `${IMAGE_BASE_URL}/${variant.varient_images[0].image_path}`
                                          : ""
                                      }
                                      alt={variant.rimg_alt_content}
                                      onClick={() => handleVariantClick(index)}
                                    />
                                    <h6 className="text-center">
                                      {variant.p_v_name}
                                    </h6>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>

                        <hr />

                        <div className="row mb-3  pt-2">
                          <dt className="col-lg-3 col-md-5 col-5">
                            Availability:
                          </dt>
                          <dd className="col-7 text-success">In Stock</dd>
                        </div>

                        <div className="row mb-4">
                          <QuantityInput
                            quantity={quantity}
                            setQuantity={setQuantity}
                            stock={product.stock}
                          />
                        </div>
                      </div>
                    )}
                  </main>
                </div>
              </div>
            </section>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary bg-danger"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  addToCart(
                    product,
                    quantity,
                    product.varients[selectedVariant],
                    onClose
                  )
                }
                disabled={product.in_stock < 1}
              >
                Add to Cart
              </button>
              <NavLink
                to={`/shop/${subcat_slug}/${slug}/`}
                type="button"
                className="btn btn-dark"
              >
                View Details
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductVariantModal;

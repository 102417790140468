import React, { useEffect, useState } from "react";
import "./SingleBlogPage.css";
import PageHeader from "./../Common/PageHeader";
import blogImg1 from "../../assets/media/blogs/blog9.webp";
import { Link, useParams } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { Helmet } from "react-helmet";

const SingleBlogPage = () => {
  const { b_slug } = useParams();
  const [blog, setBlog] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  useEffect(() => {
    apiClient
      .get(`/blog/${b_slug}`)
      .then((res) => {
        setBlog(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, [b_slug]);

  useEffect(() => {
    apiClient
      .get("/blog")
      .then((res) => {
        setRelatedBlogs(res.data.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [blog]);

  return (
    <>
      <Helmet>
        <title>{blog.meta_title}</title>
        <meta name="description" content={blog.meta_desc} />
        <meta name="keywords" content={blog.keywords} />
        {/* Add more meta tags if needed */}
      </Helmet>

      <PageHeader title="Blog Details" />
      <div className="page-section pt-5">
        <div className="container ">
          <div className="row ">
            <div className="col-lg-8">
              <div className="blog-single-wrap">
                <div className="header">
                  <div className="post-thumb">
                    <img
                      src={`${IMAGE_BASE_URL}/${blog.b_image}`}
                      alt="Blog Post Image"
                    />
                  </div>
                </div>
                <h1 className="post-title">{blog.b_title}</h1>

                <div className="post-content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: blog.b_long_desc,
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 position-relative">
              <div className="widget sticky-md-top">
                {/* <!-- Widget Categories --> */}
                <div className="widget-box">
                  <h4 className="widget-title">Category</h4>
                  <div className="divider"></div>

                  <ul className="categories">
                    <li>
                      <a href="#">LifeStyle</a>
                    </li>
                    <li>
                      <a href="#">Food</a>
                    </li>
                    <li>
                      <a href="#">Healthy</a>
                    </li>
                    <li>
                      <a href="#">Sports</a>
                    </li>
                    <li>
                      <a href="#">Entertainment</a>
                    </li>
                  </ul>
                </div>

                {/* <!-- Widget recent post --> */}
                <div className="widget-box ">
                  <h4 className="widget-title">Related Posts</h4>
                  <div className="divider"></div>

                  {relatedBlogs &&
                    relatedBlogs.slice(0, 5).map((blog) => (
                      <div className="blog-item">
                        <a className="post-thumb" href="">
                          <img src={`${IMAGE_BASE_URL}/${blog.b_image}`} />
                        </a>
                        <div className="align_center">
                          <h6 className="post-title">
                            <Link to={`/blog-detail/${blog.b_slug}`}>
                              {blog.b_title}
                            </Link>
                          </h6>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBlogPage;

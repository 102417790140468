import React, { useEffect, useState } from "react";
import CardSkeleton from "./Common/CardSkeleton";
import ProductCard from "./Shop/ProductCard";

const SearchProductList = ({ searchData }) => {
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [sortCriteria, setSortCriteria] = useState("0");

  useEffect(() => {
    if (searchData && searchData.length > 0) {
      setProducts(searchData);
      setDisplayedProducts(sortProducts(searchData));
      setIsLoading(false);
    } else {
      setIsLoading(false); // Set loading to false if searchData is not available
    }
  }, [searchData]);

  useEffect(() => {
    setDisplayedProducts(sortProducts([...products]));
  }, [sortCriteria, products]);

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const sortProducts = (products) => {
    switch (sortCriteria) {
      case "featured":
        return products.sort((a, b) => b.is_featured - a.is_featured);
      case "new_arrival":
        return products.sort(
          (a, b) => new Date(b.time_stamp) - new Date(a.time_stamp)
        );
      case "biggest_discount":
        return products.sort((a, b) => {
          const maxDiscountA = Math.max(
            ...a.varients.map((v) => v.discount_percent)
          );
          const maxDiscountB = Math.max(
            ...b.varients.map((v) => v.discount_percent)
          );
          return maxDiscountB - maxDiscountA;
        });
      case "low_to_high":
        return products.sort((a, b) => {
          const minPriceA = Math.min(...a.varients.map((v) => v.final_price));
          const minPriceB = Math.min(...b.varients.map((v) => v.final_price));
          return minPriceA - minPriceB;
        });
      case "high_to_low":
        return products.sort((a, b) => {
          const minPriceA = Math.min(...a.varients.map((v) => v.final_price));
          const minPriceB = Math.min(...b.varients.map((v) => v.final_price));
          return minPriceB - minPriceA;
        });
      default:
        return products;
    }
  };

  return (
    <>
      <div className="filter__item">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="filter__sort">
              <span>Sort By</span>
              <select
                className="sorted_by ms-2"
                name="sorted_by"
                onChange={handleSortChange}
              >
                <option value="0">Default</option>
                <option value="featured">Featured</option>
                <option value="new_arrival">New Arrival</option>
                <option value="biggest_discount">Biggest Discount</option>
                <option value="low_to_high">Price: Low to High</option>
                <option value="high_to_low">Price: High to Low</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 text-right">
            <div className="">
              <span>Products: {products.length}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {error && <em className="form_error">{error}</em>}
        {isLoading && <CardSkeleton cards={8} />}
        {displayedProducts.length > 0 ? (
          displayedProducts.map((product) => (
            <div
              className="col-lg-3 col-md-4 col-sm-6 col-6 p-2"
              key={product.product_id}
            >
              <ProductCard key={product.product_id} product={product} />
            </div>
          ))
        ) : (
          <p>No products found.</p>
        )}
      </div>
    </>
  );
};

export default SearchProductList;

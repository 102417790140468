import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../Common/PageHeader";
import { forgotPassord, login } from "../../services/userServices";
import { toast } from "react-toastify";
import UserContext from "../../contexts/UserContext";
import { Helmet } from "react-helmet";
import ForgotPassword from "./ForgotPassword";

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (formData, e) => {
    login(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          // Store user ID and email in local storage upon successful login
          localStorage.setItem("userID", response.data.data);
          localStorage.setItem("userEmail", response.data.email);
          toast.success("Login successful");
          setUser(response.data.data);

          const { state } = location;
          navigate(state ? state.from : "/account/profile");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        // Handle network errors or other unexpected errors
        toast.error("An error occurred while login.");
      });
  };

  return (
    <>
      <Helmet>
        <title>
          Do you want to get Notice of new Arrivals? Signup on Bakumia
        </title>
        <meta
          name="keywords"
          content="new unique items,creative items for home,creative home decor items,creative decorative items,creative products to sell online,creative memories items for sale,creative gift supplies,creative item for home and office,unique products for home,unique home products,unique office wall decor,interesting home products,unique decorative pieces,unique home essentials,funky home decor items,unique home decorative items,unique home office decor,unique household gadgets"
        />
        <meta
          name="description"
          content="If You don`t have account on bakumia.pk, then don`t loss the opportunity of getting notification of new arrivals so just signup your account and login with your own account "
        />
      </Helmet>
      <PageHeader title="Login Page" />
      <div className="container">
        <div className="row align-items-center pt-5 mt-2">
          {/* Left Side Content */}
          <div className="dark-grey-text col-lg-6  d-lg-block d-none">
            <h3 className="font-weight-bold mb-4 pb-2 ">Bakumia.pk</h3>
            <hr className="w-header " />
            <p className="lead w-responsive mx-auto text-muted mt-4 pt-2 mb-5 pe-5">
              To get notification of new arrival of home and kitchen products,
              it is necessary to have account on Bakumia.pk so that we can
              update you with new arrivals timely and you may avail the chance
              of shopping of products as soon as possible.
            </p>
          </div>

          {/* Right Side Foom Card */}
          <section className="h-100 col-lg-6 login_form_container">
            <div className="container h-100">
              <div className="row justify-content-sm-center h-100">
                <div className=" col-lg-10 col-md-7 col-sm-9">
                  <div className="card shadow-lg">
                    <div className="card-body p-5">
                      <h1 className="fs-4 card-title fw-bold mb-4">Login</h1>

                      <form
                        className="login_form"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="mb-3">
                          <label className="mb-2 text-muted" htmlFor="email">
                            E-Mail Address
                          </label>
                          <input
                            id="email"
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="example@gmail.com"
                            autoFocus
                            autoComplete="username"
                            {...register("email", {
                              required: true,
                              maxLength: 50,
                            })}
                          />
                          {errors.email?.type === "required" && (
                            <em className="form_error">
                              Please enter your email.
                            </em>
                          )}
                          {errors.email?.type === "maxLength" && (
                            <em className="form_error">
                              Email should be less than 50 characters.
                            </em>
                          )}
                        </div>

                        <div className="mb-3">
                          <div className="mb-2 w-100">
                            <label className="text-muted" htmlFor="password">
                              Password
                            </label>
                            <ForgotPassword />
                            {/* <Link
                              className="float-end"
                              data-bs-toggle="modal"
                              data-bs-target="#forgotPasswordModal"
                            >
                              Forgot Password?
                            </Link> */}
                          </div>
                          <input
                            id="password"
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            name="password"
                            placeholder="******"
                            autoComplete="current-password"
                            {...register("password", {
                              required: true,
                              minLength: 6,
                            })}
                          />

                          {errors.password?.type === "required" && (
                            <em className="form_error">
                              Please enter your password.
                            </em>
                          )}
                          {errors.password?.type === "minLength" && (
                            <em className="form_error">
                              Password should be at least 6 characters.
                            </em>
                          )}
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              id="showPassword"
                              className="form-check-input"
                              onChange={() => setShowPassword(!showPassword)}
                            />
                            <label
                              htmlFor="showPassword"
                              className="form-check-label"
                            >
                              Show Password
                            </label>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary ms-auto login_page_submit"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer py-3 border-0">
                      <div className="text-center">
                        Don't have an account?{" "}
                        <Link
                          to="/register/"
                          className="link-primary text-decoration-underline"
                        >
                          Create One
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default LoginPage;

import React, { useEffect, useState } from "react";
import apiClient from "../../utils/api-client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { changePassword, update } from "../../services/userServices";

const ProfileSetting = () => {
  const [userData, setUserData] = useState({});
  const [error, setError] = useState("");
  const [cities, setCities] = useState([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const {
    register: updateRegister,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const userId = localStorage.getItem("userID");

  useEffect(() => {
    if (userId) {
      apiClient
        .get(`/user/${userId}`)
        .then((res) => {
          setUserData(res.data.data);
          setValue("user_id", res.data.data.user_id);
          setValue("full_name", res.data.data.full_name);
          setValue("email", res.data.data.email);
          setValue("phone", res.data.data.phone);
          setValue("city", res.data.data.city_id_fk);
          setValue("address", res.data.data.address);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }, [userId, setValue]);

  useEffect(() => {
    apiClient
      .get("/city")
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  console.log("userData Profile:", userData);

  const onSubmit = (formData) => {
    setIsConfirming(true);
    update(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          // Display validation error messages
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((error) => {
              toast.error(error);
            });
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.data[0]);
      })
      .finally(() => {
        setIsConfirming(false);
      });
  };

  const onSubmitPasswordChange = (formData) => {
    changePassword(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          // Display validation error messages
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((error) => {
              toast.error(error);
            });
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.data[0]);
      });
  };

  return (
    <>
      <section className="p-4">
        <div className="row"></div>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title mb-4">Chnage Information</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" {...updateRegister("user_id")} />
              <div className="row mb-3">
                <div className="col-sm-3">
                  <h6 className="mb-0">Full Name</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    {...updateRegister("full_name")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-3">
                  <h6 className="mb-0">Email</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                  <input
                    type="text"
                    name
                    className="form-control"
                    {...updateRegister("email")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-3">
                  <h6 className="mb-0">Phone</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    {...updateRegister("phone")}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-sm-3">
                  <h6 className="mb-0">City</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                  <select
                    id="city"
                    className="form-control"
                    {...updateRegister("city")}
                  >
                    <option value="">Select City</option>
                    {cities &&
                      cities.map((city) => (
                        <option key={city.city_id} value={city.city_id}>
                          {city.city_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-sm-3">
                  <h6 className="mb-0">Address</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    {...updateRegister("address")}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3" />
                <div className="col-sm-9 text-secondary">
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-primary px-4"
                    defaultValue="Update Profile"
                    disabled={isConfirming}
                  >
                    {isConfirming ? "Updating..." : "Confirm"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <h4 className="card-title mb-4">Chnage Password</h4>
            <form onSubmit={handleSubmit(onSubmitPasswordChange)}>
              <input
                type="hidden"
                value={userData.user_id}
                {...updateRegister("user_id")}
              />
              <div className="row mb-3">
                <label className="col-sm-3 col-form-label">Old Password</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    name="old_password"
                    type="password"
                    {...updateRegister("old_password")}
                  />
                </div>
              </div>
              {/* end row */}
              <div className="row mb-3">
                <label className="col-sm-3 col-form-label">New Password</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    name="new_password"
                    type="password"
                    {...updateRegister("password")}
                  />
                </div>
              </div>
              {/* end row */}
              <div className="row mb-3">
                <label className="col-sm-3 col-form-label">
                  Confirm Passwrod
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    name="confirm_password"
                    type="password"
                    {...updateRegister("confirm_password")}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3" />
                <div className="col-sm-9 text-secondary">
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-primary px-4"
                    defaultValue="Update Profile"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileSetting;

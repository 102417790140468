import React, { useEffect, useState } from "react";
import "./ShopPage.css";
import PageHeader from "./../Common/PageHeader";
import ProductList from "./ProductList";

import "./ProductSidebar.css";
import apiClient from "../../utils/api-client";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

const ShopPage = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiClient
      .get("/category")
      .then((res) => {
        setCategories(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Online Shopping Stores - Online Shopping in Lahore - Karachi
        </title>
        <meta
          name="keywords"
          content="creative items,unique items,creative items to sell,creative craft items,creative gift items,creative home decor products,unique home accessories,best home decor gifts,unique kitchen decor,unique modern home decor,unique decorative items,unique house accessories,cheap home decor gifts,unique kitchen products,unique kitchenware itemsunique kitchen items,home and kitchen unique products,unique kitchen accessories,unique kitchen tools"
        />
        <meta
          name="description"
          content="Planning for online jewellery & gift shopping? Try Bakumia & get your desired products in online in Islamabad, Multan, Faisalabad & all across the Pakistan."
        />
      </Helmet>
      <PageHeader title="Shop" />
      <section className="append_products">
        <div className="container-fluid p-5">
          <div className="row ">
            <div className="col-lg-3 col-md-3  d-lg-block d-none ">
              <div className="sidebar position-relative">
                <div className="sidebar_item sticky-md-top">
                  <ul className="ul">
                    <h4>Categories</h4>
                    {error && <em className="form_error">{error}</em>}
                    {isLoading && <Skeleton count={8} />}
                    {categories &&
                      categories.map((category, index) => (
                        <li className="li" key={index}>
                          <Link
                            to={`/${category.slug}/`}
                            className="cat_products "
                          >
                            {category.p_category_name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12">
              <ProductList />
            </div>
          </div>

          <div className="col-12">
            <div className="col-md-12 mt-5 ">
              <h3 className="scat_subtitle">Our Home Products</h3>
              <p
                className="scat_long_desc mb-0"
                style={{ maxHeight: "250px", overflowY: "auto" }}
              >
                <p>
                  We welcome you at Bakumia.pk. It is one of the best{" "}
                  <b>
                    <a href="">online shopping websites</a>
                  </b>{" "}
                  that is full of household, kitchen, washroom, and daily life
                  usage products. It also has men, women, and kids` accessories.
                  Moreover, to entertain you with the technology Bakumia also
                  offers mobile and computer accessories. It has a wide
                  diversity of products in amazing colors and designs that you
                  must like and adore. You can choose every product according to
                  your choice and preference.
                </p>
                <p>
                  {" "}
                  In this digital age, from studies to shopping everything is
                  going to be online. Online shopping saves time and energy in
                  many ways. It is very important to invest in the right place
                  while online shopping. You should build your trust on an
                  authentic website that offers high-quality products in a wide
                  range and no doubt Bakumia. pk is one of these authentic
                  websites for online shopping in Pakistan. <br /> We have a
                  versatile group of products for every gender and age group.
                  There is a great collection of handbags, jewelry, and other
                  women`s accessories. If you want to do{" "}
                  <b>
                    <a href="">online jewelry shopping in Pakistan</a>
                  </b>
                  , you should search Bakumia. pk and explore different antique
                  styles and designs of jewelry. You will also love our amazing
                  collection of ladies` handbags; you will like these bags at
                  first sight. There is also a wide variety of products such as
                  makeup organizers and high-heel protectors that have great
                  advantages. These advantages have made Bakumia the best
                  platform for
                  <b>online shopping for girls</b>.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopPage;

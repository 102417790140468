import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import PageHeader from "./../Common/PageHeader";
import { register } from "./../../services/userServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiClient from "../../utils/api-client";
import { Helmet } from "react-helmet";

const RegisterPage = () => {
  const [cities, setCities] = useState([]);
  const [error, setError] = useState("");

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const onSubmit = (formData) => {
    register(formData)
      .then((response) => {
        if (response && response.data.status === false) {
          // Handle validation errors
          const errorMessages = response.data.data.map((error) => {
            return error;
          });
          toast.error(errorMessages.join(", "));
        } else if (response && response.data.status === true) {
          toast.success("Registration successful");
          setRegistrationSuccess(true);
        } else {
          // Handle other responses or errors
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        // Handle network errors or other unexpected errors
        toast.error("An error occurred while registering.");
      });
  };

  useEffect(() => {
    apiClient
      .get("/city")
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  if (registrationSuccess) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Helmet>
        <title>
          Do you want to get Notice of new Arrivals? Signup on Bakumia
        </title>
        <meta
          name="keywords"
          content="new unique items,creative items for home,creative home decor items,creative decorative items,creative products to sell online,creative memories items for sale,creative gift supplies,creative item for home and office,unique products for home,unique home products,unique office wall decor,interesting home products,unique decorative pieces,unique home essentials,funky home decor items,unique home decorative items,unique home office decor,unique household gadgets"
        />
        <meta
          name="description"
          content="If You don`t have account on bakumia.pk, then don`t loss the opportunity of getting notification of new arrivals so just signup your account and login with your own account "
        />
      </Helmet>

      <PageHeader title="Registration" />
      <div className="container">
        <div className="row align-items-center pt-5 mt-2">
          {/* Left Side Content */}
          <div className="dark-grey-text col-lg-6 d-lg-block d-none">
            <h3 className="font-weight-bold mb-4 pb-2 ">Bakumia.pk</h3>
            <hr className="w-header " />
            <p className="lead w-responsive mx-auto text-muted mt-4 pt-2 mb-5 pe-5">
              To get notification of new arrival of home and kitchen products,
              it is necessary to have account on Bakumia.pk so that we can
              update you with new arrivals timely and you may avail the chance
              of shopping of products as soon as possible.
            </p>
          </div>

          {/* Right Side Form Card */}
          <section className="h-100 col-lg-6 login_form_container">
            <div className="container h-100">
              <div className="row justify-content-sm-center h-100">
                <div className="col-lg-12 col-md-12 col-sm-9">
                  <div className="card shadow-lg">
                    <div className="card-body p-5">
                      <h1 className="fs-4 card-title fw-bold mb-4">Register</h1>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row g-3 mb-3">
                          <div className="col-md-6 col-12">
                            <label
                              htmlFor="full_name"
                              className="mb-2 text-muted"
                            >
                              Name
                            </label>
                            <input
                              id="full_name"
                              type="text"
                              className="form-control"
                              placeholder="Atiq Ullah"
                              {...formRegister("full_name", {
                                required: true,
                                minLength: 3,
                                maxLength: 30,
                              })}
                            />
                            {errors.full_name &&
                              errors.full_name.type === "required" && (
                                <span className="form_error">
                                  Please enter your name.
                                </span>
                              )}
                            {errors.full_name &&
                              errors.full_name.type === "minLength" && (
                                <span className="form_error">
                                  Name should be at least 3 characters.
                                </span>
                              )}
                            {errors.full_name &&
                              errors.full_name.type === "maxLength" && (
                                <span className="form_error">
                                  Name should be less than 30 characters.
                                </span>
                              )}
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="email" className="mb-2 text-muted">
                              Email
                            </label>
                            <input
                              id="email"
                              type="email"
                              className="form-control"
                              placeholder="example@example.com"
                              autoComplete="username"
                              {...formRegister("email", {
                                required: true,
                                maxLength: 50,
                              })}
                            />
                            {errors.email &&
                              errors.email.type === "required" && (
                                <span className="form_error">
                                  Please enter your email.
                                </span>
                              )}
                            {errors.email &&
                              errors.email.type === "maxLength" && (
                                <span className="form_error">
                                  Email should be less than 50 characters.
                                </span>
                              )}
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="phone" className="mb-2 text-muted">
                              Phone Number
                            </label>
                            <input
                              id="phone"
                              type="number"
                              className="form-control"
                              placeholder="03339016096"
                              {...formRegister("phone", {
                                required: true,
                                maxLength: 11,
                                minLength: 11,
                              })}
                            />
                            {errors.phone &&
                              errors.phone.type === "required" && (
                                <span className="form_error">
                                  Please enter your phone number.
                                </span>
                              )}
                            {errors.phone &&
                              errors.phone.type === "minLength" && (
                                <span className="form_error">
                                  Phone number must be 11 characters.
                                </span>
                              )}
                            {errors.phone &&
                              errors.phone.type === "maxLength" && (
                                <span className="form_error">
                                  Phone number exceed 11 characters.
                                </span>
                              )}
                          </div>
                          <div className="col-md-6 col-12">
                            <label
                              htmlFor="password"
                              className="mb-2 text-muted"
                            >
                              Password
                            </label>
                            <input
                              id="password"
                              type="password"
                              className="form-control"
                              placeholder="******"
                              autoComplete="current-password"
                              {...formRegister("password", {
                                required: true,
                                minLength: 6,
                              })}
                            />
                            {errors.password &&
                              errors.password.type === "required" && (
                                <span className="form_error">
                                  Please enter your password.
                                </span>
                              )}
                            {errors.password &&
                              errors.password.type === "minLength" && (
                                <span className="form_error">
                                  Password should be at least 6 characters.
                                </span>
                              )}
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="city" className="mb-2 text-muted">
                              City
                            </label>
                            <select
                              id="c_country"
                              className="form-control"
                              {...formRegister("city", {
                                required: true,
                              })}
                            >
                              <option value="">Select City</option>
                              {cities &&
                                cities.map((city) => (
                                  <option
                                    key={city.city_id}
                                    value={city.city_id}
                                  >
                                    {city.city_name}
                                  </option>
                                ))}
                            </select>

                            {errors.city && errors.city.type === "required" && (
                              <span className="form_error">
                                Please enter your city.
                              </span>
                            )}
                          </div>

                          <div className="col-md-6 col-12">
                            <label
                              htmlFor="referral_code"
                              className="mb-2 text-muted"
                            >
                              Referral Code
                            </label>
                            <input
                              id="referral_code"
                              type="text"
                              className="form-control"
                              placeholder="12345"
                              {...formRegister("referral_code", {
                                maxLength: 50,
                              })}
                            />
                          </div>

                          <div className="row g-3 mb-3">
                            <div className="col-md-12 col-12">
                              <label
                                htmlFor="address"
                                className="mb-2 text-muted "
                              >
                                Address
                              </label>
                              <input
                                id="address"
                                type="text"
                                className="form-control"
                                placeholder="123 Main St"
                                {...formRegister("address", {
                                  required: true,
                                  maxLength: 100,
                                })}
                              />
                              {errors.address &&
                                errors.address.type === "required" && (
                                  <span className="form_error">
                                    Please enter your address.
                                  </span>
                                )}
                              {errors.address &&
                                errors.address.type === "maxLength" && (
                                  <span className="form_error">
                                    Address should be less than 100 characters.
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>

                        <p className="form-text text-muted mb-3">
                          By registering you agree with our{" "}
                          <Link to="/privacy-policy/" className="link-primary">
                            Privacy Policy
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/terms-and-conditions/"
                            className="link-primary"
                          >
                            Terms and Conditions.
                          </Link>
                        </p>

                        <div className="align-items-center d-flex">
                          <button
                            type="submit"
                            className="btn btn-primary ms-auto register_page_submit"
                          >
                            Register
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer py-3 border-0">
                      <div className="text-center">
                        Already have an account?{" "}
                        <Link
                          to="/login/"
                          className="text-decoration-underline link-primary"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;

import React, { useEffect, useState } from "react";
import apiClient from "../../utils/api-client";
import ProductCard from "../Shop/ProductCard";
import CardSkeleton from "../Common/CardSkeleton";

const YouMayAlsoLikeProducts = () => {
  const [randomProducts, setRandomProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    fetchRandomProducts(page);
  }, [page]);

  const fetchRandomProducts = async (currentPage) => {
    try {
      setIsFetching(true);
      const response = await apiClient.post("/products", { page: currentPage });
      const { data, total_pages } = response.data;
      setRandomProducts((prevProducts) => {
        const uniqueNewProducts = data.filter(
          (newProduct) =>
            !prevProducts.some(
              (product) => product.product_id === newProduct.product_id
            )
        );
        return [...prevProducts, ...uniqueNewProducts];
      });
      setTotalPages(total_pages);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    } finally {
      setIsFetching(false);
    }
  };

  const loadMoreProducts = () => {
    if (page <= totalPages && !isFetching) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <section className="random_product_section">
      <div className="container-fluid">
        <div className="col-12">
          <div className="random_product_title pb-4">
            <h2>You May Also Like</h2>
            <hr className="mb-2" />
          </div>
          <div className="random_product_text">
            <p>
              Discover more reliable and durable products from the best online
              shopping website. Explore our marvelous collection of different
              items of daily life and enjoy the best shopping experience.
            </p>
          </div>
        </div>
        <div className="row">
          {error && <em className="form_error">{error}</em>}
          {isLoading && <CardSkeleton cards={8} />}
          {randomProducts &&
            randomProducts.map((product) => (
              <div
                key={product.product_id}
                className="col-lg-3 col-md-4 col-sm-6 col-6 p-2"
              >
                <ProductCard key={product.product_id} product={product} />
              </div>
            ))}
        </div>
        {page < totalPages && (
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-primary btn-lg mt-3"
                onClick={loadMoreProducts}
                disabled={isFetching}
              >
                {isFetching ? "Loading..." : "Load More"}
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default YouMayAlsoLikeProducts;

import React, { useContext, useEffect, useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import MyWishlist from "./MyWishlist";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { useForm } from "react-hook-form";
import { addShippingAddress } from "../../services/userServices";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

const AccountMain = () => {
  const [userData, setUserData] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [cities, setCities] = useState([]);

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const userId = localStorage.getItem("userID");

  useEffect(() => {
    if (userId) {
      apiClient
        .get(`/user/${userId}`)
        .then((res) => {
          setUserData(res.data.data);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }, [userId, refreshComponent]);

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        if (userId) {
          const response = await apiClient.get(
            `/get-shipping-address/${userId}`
          );
          setShippingAddress(response.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchOrderDetail();
  }, [userId, refreshComponent]);

  const handleSetDefault = (user_id_fk, sa_id) => {
    if (isButtonDisabled) {
      return;
    }
    // Disable the button immediately
    setIsButtonDisabled(true);

    // Make the API call
    apiClient
      .post("/change-address", { user_id_fk, sa_id })
      .then((response) => {
        toast.success(response.data.message);
        setRefreshComponent(true);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        // Re-enable the button after the API call (whether successful or failed)
        setIsButtonDisabled(false);
      });
  };

  useEffect(() => {
    if (refreshComponent) {
      setRefreshComponent(false);
    }
  }, [refreshComponent]);

  const onSubmit = (formData) => {
    setIsProcessing(true);
    addShippingAddress(formData)
      .then((response) => {
        if (response && response.data.status === false) {
          const errorMessages = response.data.data.map((error) => {
            return error;
          });
          toast.error(errorMessages.join(", "));
        } else if (response && response.data.status === true) {
          toast.success("Shipping Address Added successful");
          setRefreshComponent(true);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding shipping address.");
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleDeleteShippingAddress = (sa_id) => {
    apiClient
      .post(`/delete-shipping-address/${sa_id}`)
      .then((response) => {
        setRefreshComponent(true);
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    apiClient
      .get("/city")
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  return (
    <>
      <section className="p-4">
        <div className="row">
          <div className="col-1 p-0  ">
            <img
              src={`${IMAGE_BASE_URL}/${userData.profile_pic} `}
              alt=""
              className="rounded-5 img-fluid"
            />
          </div>
          <div className="col-11 flex-row my-auto ps-4">
            <h5>{userData.full_name || <Skeleton width={100} />} </h5>
            <ul className=" list-group-horizontal d-flex d-inline ">
              <li className="list-group-item me-3">
                Email: {userData.email || <Skeleton width={100} />}
              </li>
              <li className="list-group-item">
                {" "}
                Phone: {userData.phone || <Skeleton width={100} />}
              </li>
            </ul>
          </div>
        </div>

        <hr className="p-0" />

        <div className="row">
          <div className="row">
            <h5 className="pt-3 pb-1">Shipping addresses</h5>
            {shippingAddress.length > 0 ? (
              <>
                {isLoading && <Skeleton count={3} width={400} />}
                {shippingAddress &&
                  shippingAddress.map((item) => (
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className=" card card-body my-2">
                        <p className="card-text">
                          <i className="fa-solid fa-location-dot fs-5 me-1" />{" "}
                          {item.street_address} <br /> Name:{" "}
                          {item.receiver_name} <span className="ms-3" />
                          Phone: {item.receiver_phone}
                        </p>
                        <div className="align-middle">
                          {item.is_default === 1 && (
                            <span className="badge bg-secondary mt-3 mb-2">
                              Default
                            </span>
                          )}
                          {item.is_default === 0 && (
                            <span
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                handleSetDefault(item.user_id_fk, item.sa_id)
                              }
                              disabled={isButtonDisabled}
                            >
                              Set Default
                            </span>
                          )}
                          {item.is_default === 0 && (
                            <span
                              onClick={() =>
                                handleDeleteShippingAddress(item.sa_id)
                              }
                            >
                              <i
                                type="button"
                                class=" fa-solid fa-trash-can fs-5 text-dark ms-3"
                              ></i>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <p className="text-muted">No shipping addresses added</p>
            )}
          </div>
          <button
            className="col-10 col-lg-3 col-md-4 btn btn-danger border-0 mt-2 ms-3 text-white"
            type="button"
            style={{ backgroundColor: "#800080" }}
            data-bs-toggle="modal"
            data-bs-target="#shippingModal"
          >
            Add New Shipping Address
          </button>

          {/* Modal */}
          <div
            className="modal fade"
            id="shippingModal"
            tabIndex={-1}
            aria-labelledby="shippingModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div
                    className="modal-header text-white"
                    style={{ backgroundColor: "#800080" }}
                  >
                    <h1 className="modal-title fs-5" id="shippingModalLabel">
                      Add New Shipping Address
                    </h1>
                    <button
                      type="button"
                      className="btn-close text-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <div className="row g-3 mb-3">
                      <div className="col-md-6 col-12">
                        <label
                          htmlFor="receiver_name"
                          className="mb-2 text-muted"
                        >
                          Full Name
                        </label>
                        <input
                          id="full_name"
                          type="text"
                          className="form-control"
                          placeholder="Atiq Ullah"
                          {...formRegister("receiver_name", {
                            required: true,
                          })}
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label htmlFor="phone" className="mb-2 text-muted">
                          Phone Number
                        </label>
                        <input
                          id="phone"
                          type="number"
                          className="form-control"
                          placeholder="03339016096"
                          {...formRegister("receiver_phone", {
                            required: true,
                          })}
                        />
                      </div>
                    </div>

                    <div className="row g-3 mb-3">
                      <div className="col-md-6 col-12">
                        <label htmlFor="email" className="mb-2 text-muted">
                          Zip Code
                        </label>
                        <input
                          id="zip_code"
                          type="number"
                          className="form-control"
                          placeholder="25000"
                          autoComplete="zip_code"
                          {...formRegister("zip_code", {
                            required: true,
                          })}
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label htmlFor="city" className="mb-2 text-muted">
                          City
                        </label>
                        <select
                          id="c_country"
                          className="form-control"
                          {...formRegister("city_id_fk", {
                            required: true,
                          })}
                        >
                          <option value="">Select City</option>
                          {cities &&
                            cities.map((city) => (
                              <option key={city.city_id} value={city.city_id}>
                                {city.city_name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <input
                        id="email"
                        type="hidden"
                        className="form-control"
                        placeholder="example@example.com"
                        autoComplete="username"
                        value={userId}
                        {...formRegister("user_id_fk", {
                          required: true,
                        })}
                      />
                    </div>

                    <div className="row g-3 mb-3">
                      <div className="col-md-12 col-12">
                        <label htmlFor="address" className="mb-2 text-muted">
                          Address
                        </label>
                        <input
                          id="address"
                          type="text"
                          className="form-control"
                          placeholder="123 Main St"
                          {...formRegister("street_address", {
                            required: true,
                            maxLength: 100,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary border-0"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary border-0"
                      style={{ backgroundColor: "#800080" }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? "Adding... " : "Add Shiiping Address"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <hr className="p-0" />

        <div className="row">
          <h5 className="pt-3 pb-1">My Wishlist</h5>
          <MyWishlist />
        </div>
      </section>
    </>
  );
};

export default AccountMain;
